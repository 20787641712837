import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import classNames from 'classnames';
import styles from './VideoPlayer.module.scss';
import { SelectedVideoModal } from 'src/pages/UserCircle/Tabs/Videos/SelectedVideoModal/SelectedVideoModal';
import dunbarWhite from 'src/assets/images/dunbar/dunbar-white.png';
import { useMediaQuery } from '@mui/material';

interface VideoPlayerProps {
  videoUrl?: string;
  post: any;
  index: number;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, post, index }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [browser, setBrowser] = useState('');
  const isMobile = useMediaQuery('(max-width:545px)');

  const displayedChallenges = post.challenges.slice(0, 2);
  const moreChallengesCount =
    post.challenges.length - displayedChallenges.length;

  const truncateText = (text: string, maxLength: number) =>
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  const calculateRelativeTime = (date: string) => {
    const createdAt = new Date(date);
    return `${createdAt.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
    })}, ${createdAt.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })}`;
  };

  const relativeTime = calculateRelativeTime(post.createdAt);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const vendor = navigator.vendor.toLowerCase();

    if (vendor.includes('google inc.')) {
      setBrowser('Chrome');
    } else if (
      vendor.includes('apple') &&
      userAgent.includes('safari') &&
      !userAgent.includes('chrome')
    ) {
      setBrowser('Safari');
    } else {
      setBrowser('Other');
    }
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video || !videoUrl) return;

    const handleCanPlay = () => setIsLoading(false);
    const handleWaiting = () => setIsLoading(true);
    const handleLoadedData = () => setIsLoading(false);

    video.addEventListener('canplay', handleCanPlay);
    video.addEventListener('waiting', handleWaiting);
    video.addEventListener('loadeddata', handleLoadedData);

    return () => {
      video.removeEventListener('canplay', handleCanPlay);
      video.removeEventListener('waiting', handleWaiting);
      video.removeEventListener('loadeddata', handleLoadedData);
    };
  }, [videoUrl]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video || !videoUrl) return;

    const handleLoadedMetadata = () => {
      setIsLandscape(video.videoWidth > video.videoHeight);
    };

    video.addEventListener('loadedmetadata', handleLoadedMetadata);
    return () => {
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, [videoUrl]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement || !videoUrl) return;

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(videoUrl);
      hls.attachMedia(videoElement);
      return () => hls.destroy();
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.src = videoUrl;
    }
  }, [videoUrl]);

  useEffect(() => {
    if (isOpenModal && videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, [isOpenModal]);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
    setIsOpenModal(true);
  };

  const handleDunbarClick = () => {
    setIsOpenModal(true);
    videoRef.current?.pause();
  };

  return (
    <div>
      <div className={styles.videoContainer}>
        <div
          className={classNames(styles.videoWrapper, {
            [styles.videoWrapperGoogle]: browser === 'Chrome',
            [styles.videoWrapperSafari]: browser === 'Safari',
          })}
        >
          {isLoading && videoUrl && (
            <div className={styles.loaderOverlay}>
              <div className={styles.loaderSpinner}></div>
            </div>
          )}

          {videoUrl ? (
            <video
              className={classNames(styles.video, {
                [styles.landscapeVideo]: isLandscape,
              })}
              ref={videoRef}
              onClick={isMobile ? handleDunbarClick : handleVideoClick}
              controls={false}
              autoPlay={false}
              loop
              muted={false}
              data-index={index}
              playsInline
            />
          ) : (
            <div className={styles.videoUnavailable}>Video unavailable</div>
          )}

          <div className={styles.challengeOverlay}>
            <div className={styles.topData}>
              <div className={styles.circleName}>{post.tribe_name}</div>
              <div className={styles.time}>{relativeTime}</div>
            </div>
            <div className={styles.bottomTitle}>Challenges:</div>
            {displayedChallenges.map((challenge: any, index: number) => (
              <div className={styles.challengeTitle} key={index}>
                {truncateText(challenge.name, 37)}
              </div>
            ))}
            {moreChallengesCount > 0 && (
              <div className={styles.moreItem}>+{moreChallengesCount} more</div>
            )}
            <div
              className={styles.dunbarIconWrapper}
              onClick={handleDunbarClick}
            >
              <img
                src={dunbarWhite}
                alt="Dunbar"
                className={styles.dunbarIcon}
              />
            </div>
            <div className={styles.commentsCount}>
              {post.comments.length} comments
            </div>
          </div>
        </div>
      </div>

      <SelectedVideoModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        videoId={post._id}
        videoUrl={post.video_url}
      />
    </div>
  );
};

export default VideoPlayer;
