import styles from './ChallengeAndForum.module.scss';
// import { ListItem } from './ListItem/ListItem';
// import { FIRST_BLOCK, SECOND_BLOCK } from './const';

export const ChallengeAndForum = () => {
  return (
    <div className={styles.content}>
      {/* <div className={styles.blockTitle}>4- Challenges and forumt</div>
      <ListItem
        title={FIRST_BLOCK.title}
        img={FIRST_BLOCK.img}
        description={FIRST_BLOCK.description}
      />
      <ListItem title={SECOND_BLOCK.title} img={SECOND_BLOCK.img} /> */}
    </div>
  );
};
