import { ChangeEvent, FC, useState, useCallback } from 'react';
import { CommonField } from './CommonField/CommonField';
import { Header } from './Header/Header';
import { UploadVideo } from 'src/components/common/Video/Video';
import styles from './CreateCircle.module.scss';
import { DESCRIPTION_FIELD, NAME_FIELD } from './const';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Habits from 'src/components/common/Habits/Habits';
// import { Subscription } from './Subscription/Subscription';
import { ChooseCategory } from './Category/Category';
import Members from './Members/Members';
import { circleApi } from 'src/api/circles';
import { SelectChangeEvent } from '@mui/material/Select';
import { ApplyCoupon } from './ApplyCoupon/ApplyCoupon';
import { ProceedModal } from './ProceedModal/ProceedModal';
import { adminPanelApi } from 'src/api/adminPanel';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import { UploadImage } from 'src/components/common/UploadIamge/UploadImage';
import { CircleOptions } from './CircleOptions/CircleOptions';
import { AuctionTime } from './Auction/AuctionTime/AuctionTime';
import { AuctionSize } from './Auction/AuctionSize/AuctionSize';
import moment, { Moment } from 'moment';
import { auctionApi } from 'src/api/auctionApi';
import { fetchLoggedUser } from 'src/redux/slices/loggedUserSlice';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';

export interface FormData {
  tribe_name: string;
  categories: string[];
  welcome_video_id: string;
  description: string;
  member_count_max: number;
  habits: { name: string; category: string }[];
  account_type: string;
  coupon_code?: string;
  tribe_avatar_url: string;
}

interface Coupon {
  _id: string;
  client: string;
  code: string;
  status: string;
  createdAt: string;
  endDate: string;
  max_users: number;
  startDate: string;
  tribes: string[];
  updatedAt: string;
  used_count: number;
  __v: number;
}

interface GetCouponByCodeResponse {
  result: Coupon;
}

export const CreateCircle: FC = () => {
  const [seats, setSeats] = useState(0);

  const [duration, setDuration] = useState<string>('148');
  const [auctionSize, setAuctionSize] = useState<string>('20');
  const [startDate] = useState<Moment>(moment());

  const [seatsAnother, setSeatsAnother] = useState(0);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    tribe_name: '',
    categories: [],
    welcome_video_id: '',
    description: '',
    member_count_max: 1,
    habits: [],
    // membership_type: 'FREE',
    account_type: 'FIXED',
    coupon_code: '',
    tribe_avatar_url: '',
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [error, setError] = useState<string>('');

  const handleInputChange = useCallback(
    (field: keyof FormData) =>
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prevData) => ({
          ...prevData,
          [field]: event.target.value,
        }));
      },
    []
  );

  const handleImageUpdate = (fileId: string | File) => {
    console.log(fileId, 'fileId');
    if (typeof fileId === 'string') {
      setFormData({ ...formData, tribe_avatar_url: fileId });
    } else {
      console.log('Received a File object:', fileId);
    }
  };

  const handleSelectChange = useCallback(
    (field: keyof FormData) => (event: SelectChangeEvent) => {
      setFormData((prevData) => ({
        ...prevData,
        [field]: Number(event.target.value),
      }));
    },
    []
  );

  const handleCategoryClick = useCallback((category: string) => {
    setFormData((prevData) => {
      const isSelected = prevData.categories.includes(category);
      let updatedCategories: string[] = [];

      if (isSelected) {
        updatedCategories = prevData.categories.filter(
          (cat) => cat !== category
        );
      } else if (prevData.categories.length < 4) {
        updatedCategories = [...prevData.categories, category];
      } else {
        updatedCategories = prevData.categories;
      }

      return {
        ...prevData,
        categories: updatedCategories,
      };
    });
  }, []);

  // const handleSubscriptionChange = useCallback((type: string) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     account_type: type,
  //   }));
  // }, []);

  const handleHabitsChange = useCallback(
    (newHabits: { id?: string; name: string; category: string }[]) => {
      const habitsWithoutId = newHabits.map(({ name, category }) => ({
        name,
        category,
      }));
      setFormData((prevData) => ({
        ...prevData,
        habits: habitsWithoutId,
      }));
    },
    []
  );

  const validateForm = useCallback((): boolean => {
    const {
      tribe_name,
      categories,
      welcome_video_id,
      description,
      member_count_max,
      habits,
    } = formData;

    if (
      !tribe_name ||
      categories.length === 0 ||
      !welcome_video_id ||
      !description ||
      member_count_max === 0 ||
      habits.length === 0
    ) {
      setError('All fields are required');
      return false;
    }

    setError('');
    return true;
  }, [formData]);

  const createCircle = useCallback(() => {
    if (!validateForm()) return;

    setLoading(true);
    const finalize = () => setLoading(false);

    const handleError = (err: any) => {
      console.error('Full Error Object:', err);
      const message =
        err?.response?.data?.message ||
        err?.message ||
        'Something went wrong. Please try again.';
      console.error('Error:', message);
      setError(message);
      finalize();
    };

    if (formData.coupon_code?.length === 12) {
      adminPanelApi
        .getCouponsByCode(formData.coupon_code)
        .then((res) => {
          const data: GetCouponByCodeResponse = res.data;
          setSeats(data.result.max_users || 0);
          setSeatsAnother(data.result.used_count || 0);
          if (data.result.max_users < formData.member_count_max) {
            setIsOpenModal(true);
            finalize();
          } else {
            circleApi
              .createCircle(formData)
              .then((res) => {
                dispatch(fetchLoggedUser());
                setTimeout(() => {
                  navigate(PathRoutes.HomeRoute);
                  finalize();
                }, 3000);
              })
              .catch(handleError);
          }
        })
        .catch(handleError);
    } else if (formData.account_type === 'AUCTION') {
      circleApi
        .createCircle(formData)
        .then((res) => {
          const calculatedEndDate = moment(startDate).add(
            Number(duration) || 0,
            // 'hours'
            'minutes'
          );

          const data = {
            open_seat_count: Number(auctionSize),
            openDate: startDate.toDate(),
            closeDate: calculatedEndDate.toDate(),
          };

          auctionApi
            .createAuction(res.data._id, data)
            .then((result) => {
              const auctionId = result.data.result._id;
              const auctionLink = `/auction/${auctionId}`;
              dispatch(fetchLoggedUser());
              setTimeout(() => {
                navigate(auctionLink, { replace: true });
                finalize();
              }, 3000);
            })
            .catch(handleError);
        })
        .catch(handleError);
    } else {
      circleApi
        .createCircle(formData)
        .then((res) => {
          dispatch(fetchLoggedUser());
          setTimeout(() => {
            window.location.href = res.data.payment_link;
            finalize();
          }, 3000);
        })
        .catch(handleError);
    }
  }, [
    formData,
    validateForm,
    navigate,
    duration,
    startDate,
    auctionSize,
    dispatch,
  ]);

  const handleVideoUpdate = (fileId: string | File) => {
    if (typeof fileId === 'string') {
      setFormData({ ...formData, welcome_video_id: fileId });
    } else {
      console.log('Received a File object:', fileId);
    }
  };

  // useEffect(() => {
  //   console.log(formData.coupon?.length, 'formData.coupon?.length');
  //   if (formData.coupon?.length === 12) {
  //     adminPanelApi
  //       .getCouponsByCode(formData.coupon)
  //       .then((res) => {
  //         const data: GetCouponByCodeResponse = res.data;
  //         if (data.coupon.max_users < formData.member_count_max) {
  //           setIsOpenModal(true);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching coupon:', error);
  //       });
  //   }
  // }, [formData.coupon]);

  // console.log(error, '123');

  console.log(formData, '123');

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Header />
        <CommonField
          title={NAME_FIELD.title}
          description={NAME_FIELD.description}
          placeholder={NAME_FIELD.placeholder}
          charactersLength={NAME_FIELD.charactersLength}
          anhor={'info-name'}
          value={formData.tribe_name}
          setValue={handleInputChange('tribe_name')}
        />
        <ChooseCategory
          selectedCategory={formData.categories}
          handleCategoryClick={handleCategoryClick}
        />
        <UploadVideo
          defaultVideo={''}
          onUpdate={handleVideoUpdate}
          helperText="Upload a video (max 60 seconds)"
        />
        <UploadImage
          onUpdate={handleImageUpdate}
          defaultImage={
            typeof formData.tribe_avatar_url === 'string'
              ? formData.tribe_avatar_url
              : ''
          }
        >
          Add Photo
        </UploadImage>
        <CommonField
          type="textarea"
          title={DESCRIPTION_FIELD.title}
          description={DESCRIPTION_FIELD.description}
          placeholder={DESCRIPTION_FIELD.placeholder}
          charactersLength={DESCRIPTION_FIELD.charactersLength}
          anhor={'info-description'}
          value={formData.description}
          setValue={handleInputChange('description')}
        />
        <Habits
          description="“Habits” describe the daily activities and general mindset that lead to success for your Circle’s purpose."
          onHabitsChange={handleHabitsChange}
        />
        <CircleOptions
          membershipType={formData.account_type}
          setMembershipType={(type) =>
            setFormData((prev) => ({
              ...prev,
              account_type: type,
              coupon_code: type === 'AUCTION' ? '' : prev.coupon_code,
            }))
          }
        />
        {formData.account_type === 'FIXED' && (
          <>
            <Members
              value={formData.member_count_max}
              setValue={handleSelectChange('member_count_max')}
            />
            <ApplyCoupon
              value={formData.coupon_code}
              setValue={handleInputChange('coupon_code')}
            />
          </>
        )}
        {formData.account_type === 'AUCTION' && (
          <>
            <AuctionTime duration={duration} setDuration={setDuration} />
            <AuctionSize size={auctionSize} setSize={setAuctionSize} />
          </>
        )}
        {error && (
          <Typography
            color="error"
            variant="body2"
            sx={{ mb: 1, mt: 1, textAlign: 'center' }}
          >
            {error}
          </Typography>
        )}
        <button onClick={createCircle} className={styles.btn}>
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Create Circle'
          )}
        </button>
        <ProceedModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          seats={seats}
          formData={formData}
          seatsAnother={seatsAnother}
        />
      </div>
    </div>
  );
};
