import { SubmitHandler } from 'react-hook-form';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import {
  signUp,
  confirmSignUp,
  type SignUpOutput,
  type ConfirmSignUpInput,
} from 'aws-amplify/auth';
import styles from './SignUpType.module.scss';
import { FC, useState } from 'react';
import { FirstStepInput, SecondStepInput } from './const';
import { FirstStep } from './FirstStep/FirstStep';
import { SecondStep } from './SecondStep/SecondStep';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import { useUserLoginLogout } from 'src/hooks/UserLoginLogout/UserLoginLogout';
import { fetchUser } from 'src/redux/slices/userSlice';

interface SignUpTypeProps {
  setType: (step: number) => void;
  redirect?: string;
}

export const SignUpType: FC<SignUpTypeProps> = ({ setType, redirect }) => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);
  const dispatch = useAppDispatch();
  const [firstStepData, setFirstStepData] = useState<FirstStepInput | null>(
    null
  );
  const [password, setPassword] = useState('');

  const { login } = useUserLoginLogout();

  const formatPhoneNumber = (number: string) => {
    return number.replace(/\s+/g, '');
  };

  const handleSignUp: SubmitHandler<FirstStepInput> = async (data) => {
    try {
      const formattedPhoneNumber = formatPhoneNumber(data.phoneNumber);
      const output: SignUpOutput = await signUp({
        username: formattedPhoneNumber,
        password: data.password,
        options: {
          userAttributes: {
            phone_number: formattedPhoneNumber,
          },
        },
      });
      setPassword(data.password);
      setFirstStepData(data);
      handleSignUpNextSteps(output);
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleSignUpNextSteps = (output: SignUpOutput) => {
    const { nextStep } = output;
    switch (nextStep.signUpStep) {
      case 'CONFIRM_SIGN_UP':
        setStep(2);
        break;
      case 'DONE':
        console.log('Sign up complete.');
        break;
      default:
        setError('Unexpected step');
    }
  };

  const handleConfirmSignUp: SubmitHandler<SecondStepInput> = async (data) => {
    try {
      if (!firstStepData) {
        throw new Error('First step data is missing');
      }
      const formattedPhoneNumber = formatPhoneNumber(firstStepData.phoneNumber);
      const input: ConfirmSignUpInput = {
        username: formattedPhoneNumber,
        confirmationCode: data.confirmationCode,
      };
      await confirmSignUp(input);
      const result = await login({
        username: formattedPhoneNumber,
        password,
      });
      if (result?.isSignedIn) {
        dispatch(fetchUser());
        if (redirect && redirect.includes('auction')) {
          navigate(
            `${PathRoutes.CreateProfileRoute}?redirect=${encodeURIComponent(redirect)}`
          );
        } else {
          navigate(PathRoutes.CreateProfileRoute);
        }
      } else {
        setError('Login failed after confirmation');
      }
      // navigate(PathRoutes.CreateProfileRoute);
    } catch (err: any) {
      console.error('Error during confirm sign up:', err);
      setError(err.message);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className={styles.title}>Sign up</div>
          {step === 1 && <FirstStep onSubmit={handleSignUp} error={error} />}
          {step === 2 && (
            <SecondStep onSubmit={handleConfirmSignUp} error={error} />
          )}
        </Box>
        <div className={styles.border} />
        <div className={styles.footer}>
          Already have an account ?{' '}
          <div className={styles.link} onClick={() => setType(1)}>
            {' '}
            Log in
          </div>
        </div>
      </div>
    </div>
  );
};
