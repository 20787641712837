import MaterialTextField from 'src/components/material/textField';
import styles from './CommonField.module.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { FC, ChangeEvent } from 'react';

interface CommonFieldProps {
  title?: string;
  description?: string;
  anhor?: string;
  placeholder?: string;
  charactersLength?: number;
  type?: 'textarea' | 'input';
  value: string;
  setValue: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const CommonField: FC<CommonFieldProps> = ({
  title,
  description,
  anhor,
  placeholder,
  charactersLength = 100,
  type = 'input',
  value,
  setValue,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
        <a
          href={`/how-it-works#${anhor}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <InfoOutlinedIcon style={{ color: 'red', cursor: 'pointer' }} />
        </a>
      </div>
      <div className={styles.description}>{description}</div>
      <div>
        {type === 'textarea' ? (
          <TextareaAutosize
            className={styles.textarea}
            minRows={2}
            placeholder={placeholder}
            value={value}
            onChange={setValue}
          />
        ) : (
          <MaterialTextField
            variant="outlined"
            color="primary"
            placeholder={placeholder}
            value={value}
            onChange={setValue}
          />
        )}
      </div>
      <div className={styles.charactersLength}>
        {value.length}/{charactersLength}
      </div>
    </div>
  );
};
