import { FC, useState } from 'react';
import { ForgetPasswordType } from './ForgotPasswordType/ForgetPasswordType';
import { LoginType } from './LoginType/LoginType';
import styles from './RegisterModal.module.scss';
import { SignUpType } from './SignUpType/SignUpType';

interface RegisterModalProps {
  redirect?: string;
}

export const RegisterModal: FC<RegisterModalProps> = ({ redirect }) => {
  const [step, setStep] = useState(1);

  const stepMapper: { [key: number]: JSX.Element } = {
    1: <LoginType setStep={setStep} redirect={redirect} />,
    2: <SignUpType setType={setStep} redirect={redirect} />,
    3: <ForgetPasswordType setType={setStep} />,
  };

  return <div className={styles.container}>{stepMapper[step]}</div>;
};
