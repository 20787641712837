export const NAME_FIELD = {
  title: 'Name',
  description:
    'Dunbar148 Circles are small, closed, and foster genuine interactions, so most people use their real names.',
  placeholder: 'Your first and last name',
  charactersLength: 100,
};

export const EMAIL_FIELD = {
  title: 'Email',
  description:
    'Adding your email address helps keep your account secure, gives you another option to reset your password, and allows you to join a Circle where you’ve been invited by email.',
  placeholder: 'Your email address',
  charactersLength: 254,
};

export const BIO_FIELD = {
  title: 'Bio',
  description:
    'At Dunbar148, we value genuine connections over polished facades. To enrich your experience, we encourage you to be open and generous in your profile. Embrace vulnerability, celebrate your uniqueness, and let your story shine.',
  placeholder: 'About you',
  charactersLength: 1500,
};
