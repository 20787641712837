import { FC, useState } from 'react';
import styles from '../UserInfo.module.scss';
import { CreatePost } from 'src/components/common/Modals/CreatePost/CreatePost';
import { AddMembersModal } from '../../AddMembersModal/AddMembersModal';
import { CreateAuctionModal } from '../Modals/CreateAuctionModal/CreateAuctionModal';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import {
  removeCircleById,
  selectCurrentUser,
} from 'src/redux/slices/loggedUserSlice';
import { circleApi } from 'src/api/circles';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import { setActiveTabIndex } from 'src/redux/slices/tabsSlice';

interface UserActionsProps {
  isLeader: boolean;
  tribeId: string;
  leaderId: string;
  leaderName: string;
  auctionId: string;
  tribeAvatar: string;
  account_type: string;
  description: string;
}

export const UserActions: FC<UserActionsProps> = ({
  isLeader,
  tribeId,
  leaderId,
  leaderName,
  auctionId,
  tribeAvatar,
  account_type,
  description,
}) => {
  const [isOpenCreatePostModal, setIsOpenCreatePostModal] = useState(false);
  const [isOpenAddMember, setIsOpenAddMember] = useState(false);
  const [isOpenAuctionModal, setIsOpenAuctionModal] = useState(false);

  const logginedUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const deleteCircle = () => {
    circleApi.deleteCircle(tribeId).then(() => {
      dispatch(removeCircleById(tribeId));
      navigate(PathRoutes.HomeRoute);
    });
  };

  const handleShowMore = () => {
    const aboutTabIndex = isLeader ? 3 : 1;
    dispatch(setActiveTabIndex(aboutTabIndex));
  };

  const handleEditCircle = () => {
    navigate(`/edit-circle/${tribeId}`);
  };

  return (
    <div className={styles.buttons}>
      {isLeader && (
        <div>
          <div className={styles.btnsWrapper}>
            <button
              onClick={() => setIsOpenAddMember(true)}
              className={styles.btn}
            >
              Members
            </button>
            <button
              onClick={() => setIsOpenCreatePostModal(true)}
              className={styles.btn}
            >
              New Challenge
            </button>
            <button className={styles.btn} onClick={handleEditCircle}>
              Edit circle
            </button>
            {logginedUser?.role === 'ADMIN' && (
              <button className={styles.btnSec} onClick={deleteCircle}>
                Delete Circle
              </button>
            )}
          </div>
          {!auctionId && account_type === 'AUCTION' && (
            <div>
              <div className={styles.auctionTitle}>Auction</div>
              <button
                onClick={() => setIsOpenAuctionModal(true)}
                className={styles.btn}
              >
                Create an auction
              </button>
            </div>
          )}
        </div>
      )}

      <div className={styles.desc}>
        {description.length > 200 ? (
          <>
            {description.slice(0, 200)}...
            <button onClick={handleShowMore} className={styles.showMore}>
              Show more
            </button>
          </>
        ) : (
          <> {description.slice(0, 200)}...</>
        )}
      </div>

      <CreatePost
        isOpenModal={isOpenCreatePostModal}
        setIsOpenModal={setIsOpenCreatePostModal}
        tribeId={tribeId}
        leaderId={leaderId}
        leaderName={leaderName}
      />

      <CreateAuctionModal
        isOpenModal={isOpenAuctionModal}
        setIsOpenModal={setIsOpenAuctionModal}
        circleId={tribeId}
        circleName={leaderName}
        leaderName={leaderName}
        tribeAvatar={tribeAvatar}
      />

      <AddMembersModal
        isOpenModal={isOpenAddMember}
        setIsOpenModal={setIsOpenAddMember}
        circleId={tribeId}
        tribeName={leaderName}
        isLeader={isLeader}
        leaderId={leaderId}
        leaderName={leaderName}
        account_type={account_type}
      />
    </div>
  );
};
