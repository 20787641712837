import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { auctionApi } from 'src/api/auctionApi';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import styles from './Auction.module.scss';
import { AuctionProps, CircleProps } from './conts';
import { TabsAuction } from './Tabs/Tabs';
import { UserInfo } from './UserInfo/UserInfo';

export const Auction = () => {
  const [auctionData, setAuctionData] = useState<AuctionProps | null>(null);
  const [circleData, setCircleData] = useState<CircleProps | null>(null);
  const [auctionPrice, setAuctionPrice] = useState<number>(0);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      auctionApi.getPublicAuctionData(id).then((res: any) => {
        setAuctionData(res.data.result);
        setCircleData(res.data.result.tribe);
        setAuctionPrice(res.data.lowestAvailableBid);
      });
    }
  }, [id]);

  return (
    <div className={styles.container}>
      {auctionData ? (
        <>
          <UserInfo
            {...auctionData}
            {...circleData}
            auctionPrice={auctionPrice}
          />
          <TabsAuction circleData={circleData} />
        </>
      ) : (
        <div>
          <MaterialCircularPogress />
        </div>
      )}
    </div>
  );
};
