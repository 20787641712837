import { useEffect } from 'react';
import { SectionComponent } from '../SectionCompnent/SectionCompnent';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  CREATE_CIRCLE,
  DESCRIPTION,
  VIDEO,
  MEMBERS_INFO,
  PAID_OR_FREE,
  VIDEO_POST,
  HABITS_POST,
  CIRCLE_NAME,
  HABITS,
} from '../const';
import styles from './InfoCircles.module.scss';
import { Button } from '@mui/material';

export const InfoCircle = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (!hash) return;

    const id = hash.substring(1);
    let attempts = 0;
    const maxAttempts = 20;

    const tryScroll = () => {
      const element = document.getElementById(id);

      if (element && element.getBoundingClientRect().height > 0) {
        const rect = element.getBoundingClientRect();
        const scrollTop = window.scrollY || document.documentElement.scrollTop;

        const offset = rect.top + scrollTop - 200;

        window.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      } else if (attempts < maxAttempts) {
        attempts++;
        setTimeout(tryScroll, 150);
      }
    };

    tryScroll();
  }, []);

  return (
    <div className={styles.container}>
      <div id="info-create-circle">
        <SectionComponent
          isSmallTitle
          title={CREATE_CIRCLE.title}
          description={CREATE_CIRCLE.description}
        />
      </div>
      <div id="info-circle-name">
        <SectionComponent
          isSmallTitle
          title={CIRCLE_NAME.title}
          description={CIRCLE_NAME.description}
        />
      </div>
      <div id="info-email" />
      {/* <div id="info-categories">
        <SectionComponent
          isSmallTitle
          title={CATEGORIES.title}
          description={CATEGORIES.description}
        />
      </div> */}
      <div>
        <SectionComponent
          isSmallTitle
          title={DESCRIPTION.title}
          description={DESCRIPTION.description}
        />
      </div>
      <div>
        <SectionComponent
          isSmallTitle
          title={VIDEO.title}
          description={VIDEO.description}
        />
      </div>
      <div id="info-description" />
      <div>
        <SectionComponent
          isSmallTitle
          title={MEMBERS_INFO.title}
          description={MEMBERS_INFO.description}
        />
      </div>
      <div id="info-video" />
      <div id="info-habits">
        <SectionComponent
          isSmallTitle
          title={HABITS.title}
          description={HABITS.description}
        />
      </div>
      <div id="info-paid-or-free">
        <SectionComponent
          isSmallTitle
          title={PAID_OR_FREE.title}
          description={PAID_OR_FREE.description}
        />
      </div>
      <div id="members-info" />
      <h1 className={styles.title} id="info-circles">
        Create a Challenge
      </h1>
      <div id="info-video-post">
        <SectionComponent
          isSmallTitle
          title={VIDEO_POST.title}
          description={VIDEO_POST.description}
        />
      </div>
      <Button
        type="submit"
        variant="contained"
        sx={{ mb: 4, textTransform: 'none', fontSize: '18px' }}
        size="large"
      >
        <a
          className={styles.link}
          rel="noreferrer"
          href="https://www.youtube.com/watch?v=4uBA_KsM7sA"
          target="_blank"
        >
          Create inspiring Challenges
          <PlayArrowIcon sx={{ color: 'white' }} />
        </a>
      </Button>
      <div id="info-habits-post">
        <SectionComponent
          isSmallTitle
          title={HABITS_POST.title}
          description={HABITS_POST.description}
        />
      </div>
      <Button
        type="submit"
        variant="contained"
        sx={{
          textTransform: 'none',
          fontSize: '18px',
        }}
        size="large"
      >
        <a
          className={styles.link}
          rel="noreferrer"
          href="https://www.youtube.com/watch?v=w_FuRkN8tDU"
          target="_blank"
        >
          How to post your first Challenge
          <PlayArrowIcon sx={{ color: 'white' }} />
        </a>
      </Button>
    </div>
  );
};
