/* eslint-disable no-useless-escape */

import { FC, useState } from 'react';
import MaterialTextField from 'src/components/material/textField';
import styles from '../SignUpType.module.scss';

interface FirstStepProps {
  onSubmit: (data: { phoneNumber: string; password: string }) => void;
  error: string;
}

export const FirstStep: FC<FirstStepProps> = ({ onSubmit, error }) => {
  const [formValues, setFormValues] = useState({
    phoneNumber: '',
    password: '',
  });

  const [validationErrors, setValidationErrors] = useState<{
    phoneNumber?: string;
    password?: string;
  }>({});

  const handleInputChange = (field: keyof typeof formValues, value: string) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Clear validation error for the field when user starts typing
    setValidationErrors((prev) => ({
      ...prev,
      [field]: undefined,
    }));
  };

  const validateInputs = () => {
    const errors: { phoneNumber?: string; password?: string } = {};

    // Validate phone number
    if (!formValues.phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    }

    // Validate password
    if (!formValues.password) {
      errors.password = 'Password is required';
    } else if (formValues.password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
    } else if (!/[0-9]/.test(formValues.password)) {
      errors.password = 'Password must contain at least one digit';
    } else if (
      !/[\^$*.\[\]{}()?"!@#%&/\\,><':;|_~`=+\-]/.test(formValues.password)
    ) {
      errors.password = 'Password must contain at least one symbol';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateInputs()) {
      onSubmit(formValues);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <MaterialTextField
        isPhoneNumber
        label="Phone Number"
        variant="outlined"
        error={!!validationErrors.phoneNumber}
        helperText={validationErrors.phoneNumber}
        value={formValues.phoneNumber}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange('phoneNumber', e.target.value)
        }
        color="primary"
      />
      <MaterialTextField
        label="Password"
        variant="outlined"
        isPassword
        error={!!validationErrors.password}
        helperText={validationErrors.password || error}
        value={formValues.password}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange('password', e.target.value)
        }
        color="success"
      />
      <button className={styles.btn}>Continue</button>
    </form>
  );
};
