import { FC } from 'react';
import { MuiModal } from 'src/components/material/MuiModal';
import { Header } from './Header/Header';
import { SubInfo } from './SubInfo/SubInfo';
import { TotalDue } from './TotalDue/TotalDue';

interface JoinModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
  leader_name?: string;
  tribe_name?: string;
  tribe_avatar_url?: string;
  price?: number;
  open_seat_count?: number;
  closeDate?: string;
}

export const JoinModal: FC<JoinModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  leader_name,
  tribe_name,
  tribe_avatar_url,
  price,
  open_seat_count,
  closeDate,
}) => {
  return (
    <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={250}>
      <Header
        leader_name={leader_name}
        tribe_name={tribe_name}
        tribe_avatar_url={tribe_avatar_url}
        price={price}
      />
      <SubInfo open_seat_count={open_seat_count} closeDate={closeDate} />
      <TotalDue price={price} />
    </MuiModal>
  );
};
