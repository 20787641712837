import { FC } from 'react';
// import CreateIcon from '@mui/icons-material/Create';
import styles from '../UserInfo.module.scss';
// import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
// import { useMediaQuery } from '@mui/material';
// import { updateCircle } from 'src/redux/slices/circleDetailsSlice/circleDetailsSlice';

interface UserHeaderProps {
  tribeId: string;
  tribeName: string;
  isLeader: boolean;
}

export const UserHeader: FC<UserHeaderProps> = ({
  // tribeId,
  tribeName,
  // isLeader,
}) => {
  // const dispatch = useAppDispatch();
  // const [isEditing, setIsEditing] = useState(false);
  // const [localTribeName, setLocalTribeName] = useState(tribeName);
  // const inputRef = useRef<HTMLInputElement | null>(null);
  // const matches = useMediaQuery('(max-width:745px)');

  // const handleEdit = () => setIsEditing(true);

  // const handleSave = () => {
  //   const trimmedValue = inputRef.current?.value.trim();
  //   if (trimmedValue && trimmedValue !== localTribeName) {
  //     dispatch(
  //       updateCircle({
  //         id: tribeId,
  //         updates: { tribe_name: trimmedValue },
  //       })
  //     );
  //     setLocalTribeName(trimmedValue);
  //   }
  //   setIsEditing(false);
  // };

  return (
    <div className={styles.dataTop}>
      {/* {isEditing ? (
        <input
          ref={inputRef}
          className={`${styles.editInput} ${styles.absoluteInput}`}
          type="text"
          defaultValue={localTribeName}
          onBlur={handleSave}
          autoFocus
          onKeyDown={(e) => e.key === 'Enter' && handleSave()}
        />
      ) : ( */}
      <div className={styles.titleContainer}>
        <div className={styles.title}>{tribeName}</div>
        {/* {isLeader && !matches && (
            <CreateIcon
              sx={{ color: 'red', cursor: 'pointer' }}
              onClick={handleEdit}
            />
          )} */}
      </div>
      {/* )} */}
    </div>
  );
};
