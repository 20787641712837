import { useState, useRef, useEffect } from 'react';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
// import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Picker from '@emoji-mart/react';
import MaterialTextField from 'src/components/material/textField';
import styles from './Challenges.module.scss';
import { useMediaQuery } from '@mui/material';

interface ChallengesInputProps {
  onAddChallenge: (challenge: string) => void;
  onEditChallenge: (index: number, updatedChallenge: string) => void;
  onDeleteChallenge: (index: number) => void;
  challenges: string[];
}

const ChallengesInput: React.FC<ChallengesInputProps> = ({
  onAddChallenge,
  onEditChallenge,
  onDeleteChallenge,
  challenges,
}) => {
  const isMobile = useMediaQuery('(max-width:545px)');
  const [challengeText, setChallengeText] = useState('');
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [editingEmojiIndex, setEditingEmojiIndex] = useState<number | null>(
    null
  );

  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const editingEmojiPickerRef = useRef<HTMLDivElement>(null);

  const toggleEmojiPicker = () => {
    setIsEmojiOpen((prev) => !prev);
  };

  const handleEmojiClick = (emojiObject: any) => {
    setChallengeText((prevText) => prevText + emojiObject.native);
  };

  const handleAddClick = () => {
    if (challengeText.trim() === '') return;

    onAddChallenge(challengeText.trim());
    setChallengeText('');
  };

  const handleSaveEdit = (index: number, updatedText: string) => {
    onEditChallenge(index, updatedText);
    setEditingIndex(null);
    setEditingEmojiIndex(null);
  };

  const toggleEditingEmojiPicker = (index: number) => {
    setEditingEmojiIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleEditingEmojiClick = (index: number, emojiObject: any) => {
    const updatedChallenge = challenges[index] + emojiObject.native;
    onEditChallenge(index, updatedChallenge);
  };

  // Close emoji picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node)
      ) {
        setIsEmojiOpen(false);
      }
      if (
        editingEmojiPickerRef.current &&
        !editingEmojiPickerRef.current.contains(event.target as Node)
      ) {
        setEditingEmojiIndex(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.inputWrapper}>
      <div className={styles.middle}>
        <div className={styles.inputContainer}>
          <MaterialTextField
            variant="outlined"
            color="primary"
            placeholder="Today's Challenge"
            value={challengeText}
            onChange={(e) => setChallengeText(e.target.value)}
          />
          <div className={styles.maxDurationText}>
            {challengeText.length}/150 characters and emojis
          </div>
          <div style={{ position: 'relative', bottom: '77px' }}>
            {!isMobile && (
              <SentimentSatisfiedOutlinedIcon
                onClick={toggleEmojiPicker}
                className={styles.emojiIcon}
              />
            )}
          </div>
          {isEmojiOpen && (
            <div ref={emojiPickerRef} className={styles.emojiPickerContainer}>
              <Picker
                onEmojiSelect={handleEmojiClick}
                height={300}
                searchPosition="none"
                previewPosition="none"
              />
            </div>
          )}
        </div>
        <div className={styles.challengesList}>
          {challenges.map((challenge, index) => (
            <div key={index} className={styles.challengeItem}>
              {editingIndex === index ? (
                <div className={styles.editContainer}>
                  <MaterialTextField
                    variant="outlined"
                    color="primary"
                    placeholder="Edit challenge"
                    value={challenge}
                    onChange={(e) => onEditChallenge(index, e.target.value)}
                    onBlur={() => handleSaveEdit(index, challenge)}
                  />
                  <SentimentSatisfiedOutlinedIcon
                    onClick={() => toggleEditingEmojiPicker(index)}
                    className={styles.emojiIcon}
                  />
                  {editingEmojiIndex === index && (
                    <div
                      ref={editingEmojiPickerRef}
                      className={styles.emojiPickerContainer}
                    >
                      <Picker
                        onEmojiSelect={(emoji: any) =>
                          handleEditingEmojiClick(index, emoji)
                        }
                        height={300}
                        searchPosition="none"
                        previewPosition="none"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    marginTop: '10px',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>{challenge}</span>
                  {/* <CreateIcon
                    className={styles.editIcon}
                    onClick={() => handleEditClick(index)}
                  /> */}
                  <div style={{ cursor: 'pointer', display: 'flex' }}>
                    <DeleteIcon
                      className={styles.deleteIcon}
                      onClick={() => onDeleteChallenge(index)}
                      sx={{ color: '#888', marginRight: '10px' }}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <button
          className={styles.btn}
          onClick={handleAddClick}
          disabled={challenges.length >= 5}
          style={{ height: '36px' }}
        >
          Add Challenge
        </button>
      </div>

      {/* List of challenges */}

      {/* Warning for max challenges */}
      {challenges.length >= 5 && (
        <p className={styles.warningText}>
          You can only add up to 5 challenges.
        </p>
      )}
    </div>
  );
};

export default ChallengesInput;
