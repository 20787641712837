import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { auctionApi } from 'src/api/auctionApi';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import {
  clearCircleDetail,
  fetchAuctionIfLeader,
  fetchCircleBase,
  fetchCircleMessagesIfLeader,
} from 'src/redux/slices/circleDetailsSlice/circleDetailsSlice';
import { clearPosts, fetchPostsById } from 'src/redux/slices/feedSlice';
import { selectCurrentUser } from 'src/redux/slices/loggedUserSlice';
import { selectAllPosts } from 'src/redux/slices/posts/postsSlice';
import { AuctionBidsInfo } from './conts';
import TabsAuction from './Tabs/Tabs';
import styles from './UserCircle.module.scss';
import { UserInfo } from './UserInfo/UserInfo';

export const UserCircle = () => {
  const [auctionBidsInfo, setAuctionBidsInfo] =
    useState<AuctionBidsInfo | null>(null);

  const postData = useAppSelector(selectAllPosts);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);

  const {
    loading,
    circleData,
    videoUrl,
    auctionId,
    auctionData,
    videoMessages,
  } = useAppSelector((state) => state.circleDetail);

  const { id } = useParams<{ id: string }>();
  const isLeader = circleData?.leader_id === user?._id;

  useEffect(() => {
    if (!id) return;

    dispatch(clearCircleDetail());
    dispatch(clearPosts());

    dispatch(fetchCircleBase(id)).then((res) => {
      const result = res.payload as any;

      if (result?.circleData?.leader_id === user?._id) {
        dispatch(fetchAuctionIfLeader(id));
        dispatch(fetchCircleMessagesIfLeader(id));
      }

      dispatch(fetchPostsById({ id, page: 1 }));
    });
  }, [id, dispatch, user]);

  useEffect(() => {
    if (auctionId) {
      auctionApi.getSpecificAuction(auctionId).then((res) => {
        setAuctionBidsInfo(res.data as AuctionBidsInfo);
      });
    }
  }, [auctionId]);

  return (
    <div className={styles.container}>
      {loading ? (
        <MaterialCircularPogress />
      ) : circleData ? (
        <>
          <UserInfo
            {...circleData}
            id={id}
            postLength={postData.length}
            auctionId={auctionId}
            auctionData={auctionData}
            auctionBidsInfo={auctionBidsInfo}
          />
          <TabsAuction
            postData={postData}
            description={circleData.description}
            circleId={id || ''}
            videUrl={videoUrl}
            videoMessages={videoMessages}
            isLeader={isLeader}
          />
        </>
      ) : (
        <p>Error loading circle data. Please try again.</p>
      )}
    </div>
  );
};
