import Avatar from '@mui/material/Avatar';
import { useMediaQuery } from '@mui/material';
import styles from './UserInfo.module.scss';
// import arrow from 'src/assets/images/arrow/arrow-icon.png';
import { FC, useState } from 'react';
import { JoinModal } from '../JoinModal/JoinModal';
import { AuctionProps } from '../conts';
import { SubscriptionInfo } from '../SubscriptionInfo/SubscriptionInfo';

export const UserInfo: FC<AuctionProps> = ({
  leader_name,
  tribe_name,
  tribe_avatar_url,
  lowest_bid_amount_usd,
  categories,
  open_seat_count,
  createdAt,
  closeDate,
  auctionPrice,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const matches = useMediaQuery('(max-width:745px)');

  return (
    <div className={styles.container}>
      <div
        className={styles.avatar}
        style={{
          backgroundImage: matches ? `url(${tribe_avatar_url})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Avatar
          src={tribe_avatar_url}
          sx={{ width: 300, height: 300, borderRadius: '50%' }}
        >
          N
        </Avatar>
      </div>
      <div className={styles.info}>
        <div className={styles.title}>{tribe_name}</div>
        <div className={styles.name}>{leader_name}</div>

        <div>
          <SubscriptionInfo
            categories={categories}
            open_seat_count={open_seat_count}
            createdAt={createdAt}
            closeDate={closeDate}
            auctionPrice={auctionPrice}
          />
        </div>
        <button className={styles.btn} onClick={() => setIsOpenModal(true)}>
          Join Circle
        </button>
        <JoinModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          leader_name={leader_name}
          tribe_name={tribe_name}
          tribe_avatar_url={tribe_avatar_url}
          price={auctionPrice}
          open_seat_count={open_seat_count}
          closeDate={closeDate}
        />
      </div>
      <div className={styles.iconsWrapper}>
        {/* <img src={arrow} alt="" className={styles.img} /> */}
        {/* <MoreVertRoundedIcon sx={{ color: 'black' }} /> */}
      </div>
    </div>
  );
};
