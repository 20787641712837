import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthUser, getCurrentUser } from 'aws-amplify/auth';

import { RootState } from '../store/store';

interface UserState {
  user: AuthUser | null;
  userId: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  user: null,
  userId: null,
  loading: true,
  error: null,
};

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const currentUser = await getCurrentUser();
  return currentUser;
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AuthUser | null>) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
      state.userId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch user';
      });
  },
});
export const { setUser, clearUser } = userSlice.actions;

export const currentUser = (state: RootState) => state.user.user;

export default userSlice.reducer;
