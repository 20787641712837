import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { MuiModal } from 'src/components/material/MuiModal';
import { addPost } from 'src/redux/slices/feedSlice';
import ChallengesInput from './Challenges/ChallengesInput';
import styles from './CreatePost.module.scss';
import { UploadVideo } from './UploadVideo/UploadVideo';

import { postsApi } from 'src/api/posts';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import { startPollingForVideoUrl } from './helpers';

interface CreatePostProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
  tribeId: string;
  leaderId: string;
  leaderName: string;
}

export const CreatePost: FC<CreatePostProps> = ({
  isOpenModal,
  setIsOpenModal,
  tribeId,
  leaderId,
  leaderName,
}) => {
  const dispatch = useAppDispatch();

  const [videoId, setVideoId] = useState('');
  const [challenges, setChallenges] = useState<
    { name: string; category: string }[]
  >([]);
  const [videoError, setVideoError] = useState('');
  const [challengesError, setChallengesError] = useState('');

  const handleAddChallenge = (challenge: string) => {
    if (challenges.length >= 5) return;
    const newChallenge = { name: challenge, category: 'Main' };
    setChallenges([...challenges, newChallenge]);
    setChallengesError('');
  };

  const handleEditChallenge = (
    index: number,
    updatedChallenge: { name: string; category: string }
  ) => {
    const updatedChallenges = [...challenges];
    updatedChallenges[index] = updatedChallenge;
    setChallenges(updatedChallenges);
  };

  const handleDeleteChallenge = (index: number) => {
    const updatedChallenges = challenges.filter((_, i) => i !== index);
    setChallenges(updatedChallenges);
    if (updatedChallenges.length === 0) {
      setChallengesError('You should add at least one challenge');
    }
  };

  const validate = () => {
    let isValid = true;
    if (!videoId) {
      setVideoError('Video is required');
      isValid = false;
    } else {
      setVideoError('');
    }

    if (challenges.length === 0) {
      setChallengesError('You should add at least one challenge');
      isValid = false;
    } else {
      setChallengesError('');
    }

    return isValid;
  };

  const postChallenge = async () => {
    if (!validate()) return;

    const data = {
      tribe_id: tribeId,
      leader_id: leaderId,
      leader_name: leaderName,
      challenges,
      video_id: videoId,
    };

    try {
      const res = await postsApi.postChallenge(data);
      const newPost = res.data.result;

      dispatch(addPost(newPost));

      toast.success(
        'We are uploading your video. Sometimes it can take a few minutes. Come back later!'
      );

      setIsOpenModal(false);
      setVideoId('');
      setChallenges([]);
      startPollingForVideoUrl(videoId, tribeId, dispatch);
    } catch (error) {
      console.error('Failed to create or fetch posts:', error);
      toast.error('Something went wrong. Please try again.');
    }
  };

  const handleVideoUpdate = (fileId: string | File) => {
    if (typeof fileId === 'string') {
      setVideoId(fileId);
      setVideoError('');
    } else {
      console.log('Received a File object:', fileId);
    }
  };

  return (
    <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={300}>
      <div className={styles.modalContent}>
        <div className={styles.title}>Create Challenge</div>
        <UploadVideo
          defaultVideo={''}
          onUpdate={handleVideoUpdate}
          helperText="Upload a video (max 60 seconds)"
        />
        {videoError && <div className={styles.error}>{videoError}</div>}

        <ChallengesInput
          onAddChallenge={handleAddChallenge}
          onEditChallenge={(index, name) =>
            handleEditChallenge(index, { name, category: 'Main' })
          }
          onDeleteChallenge={handleDeleteChallenge}
          challenges={challenges.map((ch) => ch.name)}
        />
        {challengesError && (
          <div className={styles.error}>{challengesError}</div>
        )}

        <button className={styles.btn} onClick={postChallenge}>
          Post Challenge
        </button>
      </div>
    </MuiModal>
  );
};
