import { FC, ChangeEvent } from 'react';
import MaterialTextField from 'src/components/material/textField';
import styles from './ApplyCoupon.module.scss';

interface ApplyCouponProps {
  value?: string;
  setValue: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const ApplyCoupon: FC<ApplyCouponProps> = ({ value, setValue }) => {
  const formatCouponCode = (input: string) => {
    const cleanedInput = input.replace(/[^a-zA-Z0-9]/g, '');
    return cleanedInput.match(/.{1,3}/g)?.join('-') || '';
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;

    const cleanedInput = newValue.replace(/[^a-zA-Z0-9]/g, '');
    const formattedValue = formatCouponCode(cleanedInput);

    const truncatedCleanedValue = cleanedInput.slice(0, 12);

    setValue({
      ...event,
      target: { ...event.target, value: truncatedCleanedValue },
    });

    event.target.value = formattedValue;
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>Prepaid Members' code</div>
      </div>
      <div className={styles.description}>
        If you have already paid for the Members of this Circle, enter your code
        here.
      </div>
      <MaterialTextField
        variant="outlined"
        color="primary"
        placeholder={'Prepaid Code'}
        value={formatCouponCode(value || '')}
        onChange={handleInputChange}
      />
      <div className={styles.charactersLength}>
        {(value?.replace(/-/g, '') || '').length}/12
      </div>
    </div>
  );
};
