import React, { useState, useEffect, useRef, FC } from 'react';
import Hls from 'hls.js';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { commentsApi } from 'src/api/comments';
import { Comment } from 'src/pages/UserCircle/Tabs/Videos/SelectedVideoModal/const';
import { PostCommentInput } from '../PostCommentInput/PostCommentInput';
import styles from './CommentItem.module.scss';
import { CommentHeader } from './CommentsHeader/CommentsHeader';
import { CommentContent } from './CommentsContent/CommentsContent';
import { CommentActions } from './CommentsActions/CommentsActions';
import { CommentReplies } from './CommentReplies/CommentReplies';
import { selectCurrentUserId } from 'src/redux/slices/loggedUserSlice';

interface CommentItemProps {
  comment: Comment;
  replies: Comment[];
  videoId: string;
  onDelete: (commentId: string) => void;
  onEdit: (commentId: string, newText: string) => void;
}

export const CommentItem: FC<CommentItemProps> = ({
  comment,
  replies,
  videoId,
  onDelete,
  onEdit,
}) => {
  const [showReplies, setShowReplies] = useState(false);
  const [liked, setLiked] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [likeCount, setLikeCount] = useState(comment.like_count);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(comment.text);
  const [localReplies, setLocalReplies] = useState(replies);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const userId = useAppSelector(selectCurrentUserId);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setLiked(comment.liked_by.includes(userId));
  }, [comment.liked_by, userId]);

  useEffect(() => {
    let hls: Hls | undefined;

    if (comment.media_type === 'video' && Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(comment.media_url || '');
      hls.attachMedia(videoRef.current!);
    } else if (
      comment.media_type === 'video' &&
      videoRef.current?.canPlayType('application/vnd.apple.mpegurl')
    ) {
      videoRef.current.src = comment.media_url || '';
    }

    return () => {
      if (hls) hls.destroy();
    };
  }, [comment.media_url, comment.media_type]);

  const toggleLike = async () => {
    try {
      await commentsApi.likeComment(videoId, comment._id);
      setLiked((prev) => !prev);
      setLikeCount((prev: any) => prev + (liked ? -1 : 1));
    } catch (error) {
      console.error('Error liking the comment:', error);
    }
  };

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) videoRef.current.play();
      else videoRef.current.pause();
    }
  };

  const toggleReplies = () => setShowReplies((prev) => !prev);
  const handleReplyClick = () => setIsReplying((prev) => !prev);

  const handleNewReply = (newReply: Comment) => {
    setLocalReplies((prevReplies) => [...prevReplies, newReply]);
    setIsReplying(false);
  };

  const handleEditClick = () => setIsEditing(true);
  const handleDeleteClick = async () => {
    try {
      await commentsApi.deleteComment(videoId, comment._id);
      onDelete(comment._id);
    } catch (error) {
      console.error('Error deleting the comment:', error);
    }
  };

  const handleSaveEdit = async () => {
    try {
      await commentsApi.changeComment(videoId, comment._id, {
        text: editedText,
      });
      onEdit(comment._id, editedText);
      setIsEditing(false);
    } catch (error) {
      console.error('Error editing the comment:', error);
    }
  };

  if (comment.is_deleted) return null;

  return (
    <div className={styles.commentItem}>
      <CommentHeader
        comment={comment}
        userId={userId}
        onClickMore={(e: React.MouseEvent<HTMLElement>) =>
          setAnchorEl(e.currentTarget)
        }
        anchorEl={anchorEl}
        onClosePopover={() => setAnchorEl(null)}
        onEdit={handleEditClick}
        onDelete={handleDeleteClick}
      />
      <CommentContent
        comment={comment}
        isEditing={isEditing}
        editedText={editedText}
        setEditedText={setEditedText}
        videoRef={videoRef}
        handleVideoClick={handleVideoClick}
        handleSaveEdit={handleSaveEdit}
      />
      <CommentActions
        liked={liked}
        likeCount={likeCount}
        toggleLike={toggleLike}
        handleReplyClick={handleReplyClick}
        timestamp={new Date(comment.createdAt).toLocaleString()}
      />
      {isReplying && (
        <div className={styles.replyInput}>
          <PostCommentInput
            videoId={videoId}
            commentId={comment._id}
            isReply
            onCommentPosted={handleNewReply}
          />
        </div>
      )}
      <CommentReplies
        showReplies={showReplies}
        localReplies={localReplies}
        toggleReplies={toggleReplies}
        videoId={videoId}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    </div>
  );
};
