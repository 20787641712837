import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_ENV === 'local'
      ? 'http://127.0.0.1:4000/api/'
      : process.env.REACT_APP_API_ENV === 'staging'
        ? 'https://lkipsnzyc6.execute-api.us-west-1.amazonaws.com/prod/api/'
        : process.env.REACT_APP_API_ENV === 'prod'
          ? 'https://shb2h6g5rg.execute-api.us-east-2.amazonaws.com/prod/api/'
          : undefined,
});

export const apiNoAuth = axios.create({
  baseURL:
    process.env.REACT_APP_API_ENV === 'local'
      ? 'http://127.0.0.1:4000/api/'
      : process.env.REACT_APP_API_ENV === 'staging'
        ? 'https://njpyryjrg4.execute-api.us-west-1.amazonaws.com/prod/api/'
        : process.env.REACT_APP_API_ENV === 'prod'
          ? 'https://m89okjsf18.execute-api.us-east-2.amazonaws.com/prod/api/'
          : undefined,
});

api.interceptors.request.use(
  async (config) => {
    try {
      const session = await fetchAuthSession();
      const accessToken = session?.tokens?.idToken?.toString();
      config.headers.Authorization = `Bearer ${accessToken}`;
    } catch (error) {
      console.log('Error fetching token:', error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
