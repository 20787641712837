import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

interface HeaderProps {
  id: string;
}

export const Header: FC<HeaderProps> = ({ id }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/circle/${id}`, { replace: true });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.arrow} onClick={handleNavigate}>
          <KeyboardBackspaceIcon sx={{ color: 'black' }} />
        </div>
        <div className={styles.title}>Edit Circle</div>
      </div>
      <div className={styles.desc}>
        Everything below can be changed at any time later, too.
      </div>
    </div>
  );
};
