import styles from './MessagesVideos.module.scss';
import { VideoMessage } from '../../../pages/Home/Tabs/types';
import { MessagesVideo } from './MessagesVideo/MessagesVideo';

interface MessagesVideosProps {
  videoMessages: VideoMessage[];
  replies?: VideoMessage[];
  isOutgoing?: boolean;
  isLeader?: boolean;
}

export const MessagesVideos = ({
  videoMessages,
  replies = [],
  isOutgoing,
  isLeader,
}: MessagesVideosProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.scrollableContainer}>
        {videoMessages.length > 0 ? (
          videoMessages.map((video) => {
            const responseVideo =
              video.response_data && video.response_data._id
                ? replies.find(
                    (reply) => reply._id === video.response_data?._id
                  ) || null
                : null;

            return (
              <MessagesVideo
                key={video._id}
                video={video}
                responseVideo={responseVideo}
                isOutgoing={isOutgoing}
                isLeader={isLeader}
              />
            );
          })
        ) : (
          <div className={styles.noVideos}>
            No videos available for this circle
          </div>
        )}
      </div>
    </div>
  );
};
