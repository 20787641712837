import { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import styles from './MessagesVideo.module.scss';
import { VideoMessage } from '../../../../pages/Home/Tabs/types';
import { useMediaQuery } from '@mui/material';
import { SendResponseModal } from '../../../../pages/Home/Tabs/Modals/SendResponseModal/SendResponseModal';
import { ViewResponseModal } from '../../../../pages/Home/Tabs/Modals/ViewResponseModal/ViewResponseModal';
import moment from 'moment';

interface MessagesVideoProps {
  video: VideoMessage;
  responseVideo: VideoMessage | null;
  isOutgoing?: boolean;
  isLeader?: boolean;
}

export const MessagesVideo = ({
  video,
  responseVideo,
  isOutgoing,
  isLeader,
}: MessagesVideoProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isMobile = useMediaQuery('(max-width:545px)');
  const [isOpenSendModal, setIsOpenSendModal] = useState(false);
  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [currentPlayingVideo, setCurrentPlayingVideo] =
    useState<HTMLVideoElement | null>(null);

  useEffect(() => {
    let hls: Hls | null = null;

    if (!video.video_url || !videoRef.current) return;

    const videoElement = videoRef.current;

    const handleCanPlay = () => setIsLoading(false);
    const handleWaiting = () => setIsLoading(true);
    const handleLoadedData = () => setIsLoading(false);

    videoElement.addEventListener('canplay', handleCanPlay);
    videoElement.addEventListener('waiting', handleWaiting);
    videoElement.addEventListener('loadeddata', handleLoadedData);

    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(video.video_url);
      hls.attachMedia(videoElement);
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.src = video.video_url;
      videoElement.load();
    }

    return () => {
      videoElement.removeEventListener('canplay', handleCanPlay);
      videoElement.removeEventListener('waiting', handleWaiting);
      videoElement.removeEventListener('loadeddata', handleLoadedData);

      if (hls) {
        hls.destroy();
      }
      if (videoElement) {
        videoElement.pause();
        videoElement.src = '';
      }
    };
  }, [video.video_url]);

  useEffect(() => {
    let hls: Hls | null = null;

    if (!video.video_url || !videoRef.current) return;

    const videoElement = videoRef.current;

    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(video.video_url);
      hls.attachMedia(videoElement);
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.src = video.video_url;
      videoElement.load();
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
      if (videoElement) {
        videoElement.pause();
        videoElement.src = '';
      }
    };
  }, [video.video_url]);

  const handleMouseEnter = () => {
    if (!isMobile && videoRef.current) {
      if (currentPlayingVideo && currentPlayingVideo !== videoRef.current) {
        currentPlayingVideo.pause();
      }
      videoRef.current.play().catch(() => {
        console.warn('Autoplay prevented by browser');
      });
      setCurrentPlayingVideo(videoRef.current);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile && videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleButtonClick = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }

    if (responseVideo) {
      setIsOpenViewModal(true);
    } else {
      setIsOpenSendModal(true);
    }
  };

  const videoData = responseVideo ?? video;

  return (
    <div
      className={styles.videoContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.videoWrapper}>
        {isLoading && (
          <div className={styles.loaderOverlay}>
            <div className={styles.loaderSpinner}></div>
          </div>
        )}
        <video ref={videoRef} className={styles.videoPlayer} playsInline>
          Your browser does not support the video tag.
        </video>
      </div>
      <div className={styles.challengeOverlay}>
        {video.msgType === 'REPLY' ? (
          <div className={styles.memberName}>{video.member_name}</div>
        ) : (
          video?.leader_name && (
            <div className={styles.memberName}>{video.leader_name}</div>
          )
        )}
        {videoData?.createdAt && (
          <div className={styles.date}>
            {moment(videoData.createdAt).format('MMM D, h:mm A')}
          </div>
        )}
        <div className={styles.videoDetails}>
          {video?.title && <div className={styles.title}>{video.title}</div>}
        </div>
        {!isLeader && !isOutgoing && (
          <button className={styles.btn} onClick={handleButtonClick}>
            {responseVideo ? 'View Reply' : 'Reply'}
          </button>
        )}
      </div>
      <SendResponseModal
        isOpenModal={isOpenSendModal}
        setIsOpenModal={setIsOpenSendModal}
        leaderId={video.leader_id}
        leaderName={video.leader_name}
        circleId={video.tribe_id}
        memberName={video.member_name}
        memberId={video.member_id}
        messageId={video._id}
      />
      <ViewResponseModal
        isOpenModal={isOpenViewModal}
        setIsOpenModal={setIsOpenViewModal}
        responseVideo={responseVideo}
      />
    </div>
  );
};
