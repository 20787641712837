import { configureStore } from '@reduxjs/toolkit';

import couponsReducer from '../slices/coupons/couponsSlice';
import feedReducer from '../slices/feedSlice';
import loggedUserReducer from '../slices/loggedUserSlice';
import membersReducer from '../slices/members/Slice/membersSlice';
import postsReducer from '../slices/posts/postsSlice';
import userReducer from '../slices/userSlice';
import circleDetailReducer from '../slices/circleDetailsSlice/circleDetailsSlice';
import tabsReducer from '../slices/tabsSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    loggedUser: loggedUserReducer,
    members: membersReducer,
    coupons: couponsReducer,
    feed: feedReducer,
    posts: postsReducer,
    circleDetail: circleDetailReducer,
    tabs: tabsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
