import { useEffect, useState } from 'react';

import { CircularProgress, Typography } from '@mui/material';
import { usersApi } from 'src/api/user';
import { UploadImage } from 'src/components/common/UploadIamge/UploadImage';
import { CommonFiled } from './CommonField/CommonField';
import styles from './CreateProfile.module.scss';
import { Header } from './Header/Header';
// import Birthday from './Birthday/Birthday';
import { useLocation, useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import { BIO_FIELD, EMAIL_FIELD, NAME_FIELD } from './const';

interface ProfileFormData {
  phone_number: string;
  cognito_id: string;
  full_name: string;
  avatar_url: string;
  bio: string;
  // birthday: string;
  email: string;
}

export const CreateProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const redirectPath = searchParams.get('redirect') || PathRoutes.HomeRoute;

  const [formData, setFormData] = useState<ProfileFormData>({
    phone_number: '',
    cognito_id: '',
    full_name: '',
    avatar_url: '',
    bio: '',
    // birthday: '',
    email: '',
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const handleImageUpdate = (fileId: string | File) => {
    if (typeof fileId === 'string') {
      setFormData({ ...formData, avatar_url: fileId });
    } else {
      console.log('Received a File object:', fileId);
    }
  };

  useEffect(() => {
    setLoading(true);
    usersApi
      .userMe()
      .then((res: any) => {
        const { phone_number, _id } = res.data.result;
        setFormData((prevData) => ({
          ...prevData,
          phone_number,
          cognito_id: _id,
        }));
      })
      .catch(() => {
        // setError('Failed to fetch user data.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // const validateForm = (): boolean => {
  //   if (
  //     !formData.phone_number ||
  //     !formData.full_name ||
  //     // !formData.avatar_url ||
  //     !formData.bio ||
  //     // !formData.birthday ||
  //     !formData.email
  //   ) {
  //     setError('All fields are required');
  //     return false;
  //   }
  //   setError('');
  //   return true;
  // };

  const createProfile = () => {
    // if (!validateForm()) {
    //   return;
    // }

    // const profileNewData = {
    //   phone_number: formData.phone_number,
    //   full_name: formData.full_name,
    //   avatar_url: formData.avatar_url,
    //   bio: formData.bio,
    //   // birthday: formData.birthday,
    //   email: formData.email,
    // };

    // usersApi
    //   .createProfile(profileNewData, formData.cognito_id)
    //   // .then(() => {
    //   //   navigate(PathRoutes.HomeRoute);
    //   // })
    //   .then(() => {
    //     if (redirectPath.includes('auction')) {
    //       navigate(redirectPath);
    //     } else {
    //       navigate(PathRoutes.HomeRoute);
    //     }
    //   })
    //   .catch(() => {
    //     setError('An error occurred while creating the profile.');
    //   });

    if (redirectPath.includes('auction')) {
      navigate(redirectPath);
    }
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Header />
        <CommonFiled
          title={NAME_FIELD.title}
          description={NAME_FIELD.description}
          placeholder={NAME_FIELD.placeholder}
          charactersLength={NAME_FIELD.charactersLength}
          isIcon
          anhor="info-name"
          value={formData.full_name}
          onChange={(e) =>
            setFormData({ ...formData, full_name: e.target.value })
          }
        />
        <UploadImage
          onUpdate={handleImageUpdate}
          defaultImage={
            typeof formData.avatar_url === 'string' ? formData.avatar_url : ''
          }
        >
          Add Photo
        </UploadImage>
        <CommonFiled
          type="textarea"
          title={BIO_FIELD.title}
          description={BIO_FIELD.description}
          placeholder={BIO_FIELD.placeholder}
          charactersLength={BIO_FIELD.charactersLength}
          anhor="info-bio"
          value={formData.bio}
          onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
        />
        {/* <Birthday
          value={formData.birthday}
          setValue={(value) => setFormData({ ...formData, birthday: value })}
        /> */}
        <CommonFiled
          title={EMAIL_FIELD.title}
          description={EMAIL_FIELD.description}
          placeholder={EMAIL_FIELD.placeholder}
          charactersLength={EMAIL_FIELD.charactersLength}
          isIcon
          anhor="info-email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        {error && (
          <Typography
            color="error"
            variant="body2"
            sx={{ mb: 1, mt: 1, textAlign: 'center' }}
          >
            {error}
          </Typography>
        )}
        <button className={styles.btn} onClick={createProfile}>
          Create Profile
        </button>
      </div>
    </div>
  );
};
