import moment from 'moment';

export const getTimeLeft = (closeDate?: string) => {
  if (!closeDate) return 'N/A';

  const now = moment();
  const closeTime = moment(closeDate);

  if (!closeTime.isValid()) return 'Invalid date';

  const duration = moment.duration(closeTime.diff(now));

  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours()) % 24;
  const minutes = Math.floor(duration.asMinutes()) % 60;

  if (days > 0) {
    return `${days} days, ${hours} hours remaining`;
  } else {
    return `${hours} hours, ${minutes} minutes remaining`;
  }
};
