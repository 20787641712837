import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { themeColors } from 'src/theme';
import { useState } from 'react';
import { MessagesVideos } from '../../../components/common/MessagesVideos/MessagesVideos';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabsMessages() {
  const [value, setValue] = useState(0);

  const videoMessages = useAppSelector(
    (state) => state.circleDetail.memberMessages
  );
  const loading = useAppSelector(
    (state) => state.circleDetail.loadingMemberMessages
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (loading) {
    return <MaterialCircularPogress />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{ sx: { background: themeColors.text.dark100 } }}
          sx={{
            '& button': { color: themeColors.text.dark70 },
            '& button.Mui-selected': { color: themeColors.text.dark100 },
          }}
        >
          <Tab label="Incoming" {...a11yProps(0)} />
          <Tab label="Outgoing" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <MessagesVideos
          videoMessages={videoMessages.result}
          replies={videoMessages.replies}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <MessagesVideos videoMessages={videoMessages.replies} isOutgoing />
      </CustomTabPanel>
    </Box>
  );
}
