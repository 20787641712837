import styles from './Home.module.scss';
import { UserInfo } from './UserInfo/UserInfo';
import { SubscriptionInfo } from './SubscriptionInfo/SubscriptionInfo';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import {
  selectFullUserInfo,
  selectLoggedUserInitialized,
} from 'src/redux/slices/loggedUserSlice';
import TabsMessages from './Tabs/Tabs';
import { fetchMessagesForMember } from 'src/redux/slices/circleDetailsSlice/circleDetailsSlice';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import useAppSelector from 'src/hooks/Redux/useAppSelector';

export const Home = () => {
  const dispatch = useAppDispatch();
  const fullUserInfo = useAppSelector(selectFullUserInfo);
  const loggedUserInitialized = useAppSelector(selectLoggedUserInitialized);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchMessagesForMember());
  }, [dispatch]);

  useEffect(() => {
    if (loggedUserInitialized && !fullUserInfo?.result.full_name) {
      navigate(PathRoutes.CreateProfileRoute);
    }
  }, [fullUserInfo, navigate, loggedUserInitialized]);

  return (
    <div className={styles.container}>
      {fullUserInfo ? (
        <>
          <UserInfo {...fullUserInfo} />
          <SubscriptionInfo {...fullUserInfo} />
          <TabsMessages />
        </>
      ) : (
        <p>Error loading user data. Please try again.</p>
      )}
    </div>
  );
};
