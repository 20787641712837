import { FC } from 'react';
import styles from './SubscriptionInfo.module.scss';
import { AuctionProps } from '../conts';
// import { CATEGORIES } from 'src/const/Categories/Categories';
// import { CategoryItem } from 'src/components/common/Category/CategoryItem/CategoryItem';
import moment from 'moment';

export const SubscriptionInfo: FC<AuctionProps> = ({
  categories,
  open_seat_count,
  // createdAt,
  closeDate,
  auctionPrice,
}) => {
  // const uniqueCategories = Array.from(new Set(categories));

  // const getCategoryImage = (category: string) => {
  //   const categoryData = CATEGORIES.find(
  //     (cat: any) => cat.name === category.toLowerCase()
  //   );
  //   return categoryData
  //     ? categoryData.img
  //     : CATEGORIES.find((cat: any) => cat.name === 'other')?.img;
  // };

  const getTimeLeft = (closeDate?: string) => {
    if (!closeDate) return 'N/A';

    const now = moment();
    const closeTime = moment(closeDate);

    if (!closeTime.isValid()) return 'Invalid date';

    const duration = moment.duration(closeTime.diff(now));

    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours()) % 24;
    const minutes = Math.floor(duration.asMinutes()) % 60;

    if (days > 0) {
      return `${days} days, ${hours} hours left`;
    } else {
      return `${hours} hours, ${minutes} minutes left`;
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.info}>{getTimeLeft(closeDate)}</div>
        <div className={styles.info}>
          {auctionPrice / 100}$<span>/month</span>
        </div>
        <div className={styles.info}>
          {open_seat_count} <span>spots</span>
        </div>
      </div>
      {/* <div className={styles.categories}>
        <div className={styles.categoryList}>
          {uniqueCategories.map((category, index) => (
            <CategoryItem
              key={index}
              item={{ name: category, img: getCategoryImage(category) }}
            />
          ))}
        </div>
      </div> */}
    </div>
  );
};
