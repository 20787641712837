export interface PollingOptions<T> {
  intervalMs?: number;
  maxTries?: number;
  onSuccess: (data: T) => void;
  onError?: (error: any) => void;
  shouldStop: (data: T) => boolean;
}

export const poll = async <T>(
  fetchFn: () => Promise<T>,
  options: PollingOptions<T>
) => {
  const {
    intervalMs = 5000,
    maxTries = 60,
    onSuccess,
    onError,
    shouldStop,
  } = options;

  let tries = 0;

  const interval = setInterval(async () => {
    tries++;

    try {
      const result = await fetchFn();

      if (shouldStop(result)) {
        clearInterval(interval);
        onSuccess(result);
      } else if (tries >= maxTries) {
        clearInterval(interval);
        onError?.(new Error('Polling timed out.'));
      }
    } catch (error) {
      clearInterval(interval);
      onError?.(error);
    }
  }, intervalMs);
};
