import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import { FC } from 'react';
import styles from './CircleOptions.module.scss';

interface CircleOptionsProps {
  membershipType: string;
  setMembershipType: (type: string) => void;
}

export const CircleOptions: FC<CircleOptionsProps> = ({
  membershipType,
  setMembershipType,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMembershipType(event.target.value);
  };

  return (
    <div className={styles.container}>
      <FormControl component="fieldset">
        <div className={styles.title}>Circle type</div>
        <div className={styles.description}>
          Set a fixed subscription price for all Members, or let the market
          determine the price through an auction.
        </div>
        <RadioGroup value={membershipType} onChange={handleChange}>
          <FormControlLabel
            value="AUCTION"
            control={<Radio />}
            label="Auction"
          />
          <FormControlLabel value="FIXED" control={<Radio />} label="Fixed" />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
