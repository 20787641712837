import { useState } from 'react';
import { Circleitem } from './CircleItem/CircleItem';
import styles from './Circles.module.scss';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { selectTribeDetails } from 'src/redux/slices/loggedUserSlice';

export const Circles = () => {
  const [selectedCircle, setSelectedCircle] = useState<string | null>(null);
  const getTribeDetails = useAppSelector(selectTribeDetails);

  const approvedCircles = getTribeDetails
    ? getTribeDetails
        .filter((item) => item.status === 'APPROVED')
        .sort((a, b) => {
          const roleOrder = { LEADER: 1, 'CO-LEADER': 2, MEMBER: 3 } as const;
          const aRole = a.role as keyof typeof roleOrder;
          const bRole = b.role as keyof typeof roleOrder;

          return (roleOrder[aRole] || 4) - (roleOrder[bRole] || 4);
        })
    : [];
  return (
    <div className={styles.container}>
      <div className={styles.title}>Circles</div>

      {approvedCircles.length > 0 ? (
        <div
          className={`${styles.list} ${approvedCircles.length > 5 ? styles.scrollable : ''}`}
        >
          {approvedCircles.map((item) => (
            <Circleitem
              item={item}
              key={item._id}
              selectedCircle={selectedCircle}
              setSelectedCircle={setSelectedCircle}
            />
          ))}
        </div>
      ) : (
        <div className={styles.warning}>
          Your Circles will show up here when you are invited to a Circle or
          start one of your own.
        </div>
      )}
    </div>
  );
};
