import { useEffect } from 'react';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { fetchUser } from 'src/redux/slices/userSlice';

const useUserSession = () => {
  const dispatch = useAppDispatch();
  const { user, loading } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  return { user, loading };
};

export default useUserSession;
