import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';

interface MaterialCircularProgressProps {
  height?: string;
  size?: number;
}

export const MaterialCircularPogress: FC<MaterialCircularProgressProps> = ({
  height = '100vh',
  size = 72,
}) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height,
      }}
    >
      <CircularProgress size={size} sx={{ color: 'red' }} />
    </div>
  );
};
