import { FC } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { themeColors } from 'src/theme';

interface SelectOption {
  value: string | number;
  label: string;
}

interface MuiSelectProps {
  label: string;
  value: string;
  options: SelectOption[];
  onChange: (event: SelectChangeEvent) => void;
  width?: string;
}

export const MuiSelect: FC<MuiSelectProps> = ({
  label,
  value,
  options,
  onChange,
  width = '80',
}) => {
  return (
    <Box sx={{ width }}>
      <FormControl fullWidth>
        <Select
          labelId={`${label}-select-label`}
          id={`${label}-select`}
          value={value}
          label={label}
          onChange={onChange}
          sx={{
            bgcolor: '#f5f5f7',
            color: themeColors.info.main,
            borderRadius: 1,
            height: width ? 56 : 40,
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{ color: 'black' }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
