import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import classNames from 'classnames';
import { FC, useCallback, useRef, useState } from 'react';
import { postsApi } from 'src/api/posts';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import { CommentsSideProps } from 'src/pages/UserCircle/conts';
import { Comment } from 'src/pages/UserCircle/Tabs/Videos/SelectedVideoModal/const';
import styles from './CommentsSide.module.scss';
import { CommentItem } from './CommetItem/CommentItem';
import { PostCommentInput } from './PostCommentInput/PostCommentInput';

export const CommentsSide: FC<CommentsSideProps> = ({
  commentsData,
  videoId,
  loading,
  isChallengePage,
}) => {
  const [comments, setComments] = useState<Comment[]>(commentsData.result);
  const [children, setChildren] = useState<Comment[][]>(commentsData.children);
  const [page, setPage] = useState(commentsData.currentPage || 1);
  const [totalPages, setTotalPages] = useState(commentsData.totalPages || 1);
  const [loadingMore, setLoadingMore] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastCommentRef = useCallback(
    (node: HTMLDivElement) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && page < totalPages) {
          loadMoreComments();
        }
      });

      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingMore, page, totalPages]
  );

  const loadMoreComments = async () => {
    setLoadingMore(true);
    try {
      const nextPage = page + 1;
      const res = await postsApi.getCommentsForSelectedPost(videoId, nextPage);
      setComments((prev) => [...prev, ...res.data.result]);
      setChildren((prev) => [...prev, ...res.data.children]);
      setPage(res.data.currentPage);
      setTotalPages(res.data.totalPages);
    } catch (err) {
      console.error('Failed to load more comments', err);
    } finally {
      setLoadingMore(false);
    }
  };

  const handleNewComment = (newComment: Comment) => {
    setComments((prev) => [newComment, ...prev]);
    setChildren((prev) => [
      [
        /* optional replies */
      ],
      ...prev,
    ]);
  };

  const handleDeleteComment = (commentId: string) => {
    const index = comments.findIndex((comment) => comment._id === commentId);
    setComments((prev) => prev.filter((comment) => comment._id !== commentId));
    setChildren((prev) => prev.filter((_, i) => i !== index));
  };

  const handleEditComment = (commentId: string, newText: string) => {
    setComments((prev) =>
      prev.map((comment) =>
        comment._id === commentId ? { ...comment, text: newText } : comment
      )
    );
  };

  return (
    <div>
      <div className={styles.header}>
        <SmsOutlinedIcon sx={{ color: 'black' }} />
        <span className={styles.totalComments}>
          Total Comments: {comments.length}
        </span>
      </div>

      <div
        className={classNames(styles.commentsList, {
          [styles.isChallengePage]: isChallengePage,
        })}
      >
        {comments.map((comment: Comment, index: number) => {
          const isLast = index === comments.length - 1;
          return (
            <div key={comment._id} ref={isLast ? lastCommentRef : null}>
              <CommentItem
                comment={comment}
                replies={children[index] || []}
                videoId={videoId}
                onDelete={handleDeleteComment}
                onEdit={handleEditComment}
              />
            </div>
          );
        })}
        {loadingMore && (
          <div style={{ padding: 12 }}>
            <MaterialCircularPogress height="100px" size={42} />
          </div>
        )}
      </div>

      <div className={styles.replyBlock}>
        <PostCommentInput
          videoId={videoId}
          onCommentPosted={handleNewComment}
        />
      </div>
    </div>
  );
};
