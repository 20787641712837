import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { themeColors } from 'src/theme';
import { useMediaQuery } from '@mui/material';
import { Videos } from './Videos/Videos';
import { TabPanelProps, TabsAuctionProps } from '../conts';
import styles from './Tabs.module.scss';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { selectCurrentUser } from 'src/redux/slices/loggedUserSlice';
import { MessagesVideos } from 'src/components/common/MessagesVideos/MessagesVideos';
import { WelcomeVideo } from './WelcomeVideo/WelcomeVideo';
import { selectCircleDetailsData } from 'src/redux/slices/circleDetailsSlice/circleDetailsSlice';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import { setActiveTabIndex } from 'src/redux/slices/tabsSlice';

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function TabsAuction({
  circleId,
  videoMessages,
}: TabsAuctionProps) {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const circleData = useAppSelector(selectCircleDetailsData);
  const user = useAppSelector(selectCurrentUser);
  const externalTab = useAppSelector((state) => state.tabs.activeTabIndex);
  const dispatch = useAppDispatch();

  const { leader_id, description } = circleData || {};
  const isLeader = user?._id === leader_id;

  const [value, setValue] = useState(0);

  const tabList = [
    { label: 'Challenges', component: <Videos circleId={circleId} /> },
    ...(isLeader
      ? [
          {
            label: 'Incoming',
            component: (
              <MessagesVideos
                videoMessages={videoMessages?.replies || []}
                replies={videoMessages?.result || []}
                isLeader={true}
              />
            ),
          },
          {
            label: 'Outgoing',
            component: (
              <MessagesVideos
                videoMessages={videoMessages?.result || []}
                isOutgoing
              />
            ),
          },
        ]
      : []),

    {
      label: 'About',
      component: (
        <div className={styles.aboutTab}>
          <div className={styles.descContainer}>
            <div className={styles.description}>{description}</div>
          </div>
        </div>
      ),
    },

    {
      label: 'Welcome video',
      component: (
        <div className={styles.videos}>
          <WelcomeVideo circleData={circleData} isLeader={isLeader} />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (typeof externalTab === 'number') {
      setValue(externalTab);
    }
  }, [externalTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    dispatch(setActiveTabIndex(newValue));
  };

  return (
    <Box sx={{ width: '100%', marginTop: '20px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="dynamic tabs example"
          TabIndicatorProps={{ sx: { background: themeColors.text.dark100 } }}
          variant={isMobile ? 'scrollable' : 'standard'}
          scrollButtons={isMobile ? 'auto' : false}
          allowScrollButtonsMobile
          sx={{
            '& button': { color: themeColors.text.dark70 },
            '& button.Mui-selected': { color: themeColors.text.dark100 },
          }}
        >
          {tabList.map((tab, index) => (
            <Tab key={index} label={tab.label} id={`simple-tab-${index}`} />
          ))}
        </Tabs>
      </Box>
      {tabList.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.component}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
