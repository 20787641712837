import { FC, ChangeEvent } from 'react';
import MaterialTextField from 'src/components/material/textField';
import styles from './CommonField.module.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';

interface CommonFiledProps {
  title?: string;
  description?: string;
  anhor?: string;
  placeholder?: string;
  charactersLength?: number;
  type?: 'textarea' | 'input';
  isIcon?: boolean;
  value?: string;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const CommonFiled: FC<CommonFiledProps> = ({
  title,
  description,
  anhor,
  placeholder,
  charactersLength = 100,
  type = 'input',
  isIcon,
  value,
  onChange,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
        {isIcon && (
          <div>
            <a
              href={`/how-it-works#${anhor}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <InfoOutlinedIcon style={{ color: 'red', cursor: 'pointer' }} />
            </a>
          </div>
        )}
      </div>
      <div className={styles.description}>{description}</div>
      <div>
        {type === 'textarea' ? (
          <TextareaAutosize
            className={styles.textarea}
            minRows={2}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        ) : (
          <MaterialTextField
            variant="outlined"
            color="primary"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        )}
      </div>
      <div className={styles.charactersLength}>
        {value?.length}/{charactersLength} characters
      </div>
    </div>
  );
};
