import { FC, useState, useEffect } from 'react';
import { Button } from '@mui/material';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styles from './Habits.module.scss';
import EditIcon from '@mui/icons-material/Edit';
import { AddHabitModal } from './AddHabitModal/AddHabitModal';
// import { HashLink as Link } from 'react-router-hash-link';

interface HabitsProps {
  description: string;
  habitsArr?: any;
  onHabitsChange: (
    habits: { id: string; name: string; category: string }[]
  ) => void;
}

interface Habit {
  id: string;
  name: string;
  category: string;
}

export const Habits: FC<HabitsProps> = ({
  description,
  onHabitsChange,
  habitsArr,
}) => {
  const [habits, setHabits] = useState<Habit[]>(habitsArr ? habitsArr : []);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedHabit, setSelectedHabit] = useState<Habit | null>(null);

  useEffect(() => {
    onHabitsChange(habits);
  }, [habits, onHabitsChange]);

  const addOrUpdateHabit = (habit: Habit, isEditing: boolean) => {
    if (isEditing) {
      // Update existing habit by id
      setHabits((prevHabits) =>
        prevHabits.map((h) => (h.id === habit.id ? habit : h))
      );
    } else {
      if (habits.length < 5) {
        const newHabit = { ...habit, id: Date.now().toString() }; // Assign unique ID
        setHabits([...habits, newHabit]);
      }
    }
  };

  const deleteHabit = (habitToDelete: Habit) => {
    setHabits((prevHabits) =>
      prevHabits.filter((habit) => habit.id !== habitToDelete.id)
    );
  };

  const openModalWithHabit = (habit: Habit) => {
    setSelectedHabit(habit);
    setIsOpenModal(true);
  };

  const handleAddHabitClick = () => {
    setSelectedHabit(null);
    setIsOpenModal(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>Habits</div>
        {/* <Link to={`/how-it-works#info-habits`}>
          <InfoOutlinedIcon style={{ color: 'red', cursor: 'pointer' }} />
        </Link> */}
      </div>
      <div className={styles.description}>{description}</div>
      <div className={styles.habitList}>
        {habits.map((habit, index) => (
          <Button
            key={index}
            className={styles['btn']}
            sx={{ height: '56px' }}
            onClick={() => openModalWithHabit(habit)}
          >
            {habit.name}
            <EditIcon sx={{ color: 'black' }} />
          </Button>
        ))}
      </div>
      <Button
        className={styles['btn']}
        onClick={handleAddHabitClick}
        disabled={habits.length >= 5}
        sx={{ height: '56px' }}
      >
        {habits.length >= 5 ? (
          <div>You already have 5 habits</div>
        ) : (
          <div> Add Habit</div>
        )}
      </Button>
      <AddHabitModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        onSave={addOrUpdateHabit}
        initialHabit={selectedHabit}
        onDelete={deleteHabit}
      />
      <div className={styles.charactersLength}>Up to 5 habits </div>
    </div>
  );
};

export default Habits;
