import { CircularProgress } from '@mui/material';
// import cn from 'classnames';
import { FC, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { auctionApi } from 'src/api/auctionApi';
import { RegisterModal } from 'src/components/common/Sidebar/RegisterModal/RegisterModal';
import { MuiModal } from 'src/components/material/MuiModal';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { currentUser } from 'src/redux/slices/userSlice';
import styles from './TotalDue.module.scss';

interface TotalDueProps {
  price: number | undefined;
}
export const ValidBidAmounts = [
  1000, 2500, 5000, 10000, 25000, 50000, 100000, 250000, 500000,
];

export const TotalDue: FC<TotalDueProps> = ({ price = 0 }) => {
  // const [selectedBid, setSelectedBid] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const user = useAppSelector(currentUser);
  const { id } = useParams();
  const location = useLocation();

  const makeBid = () => {
    setError(null);
    setLoading(true);

    if (!user) {
      setLoading(false);
      return;
    }

    if (id) {
      auctionApi
        .bidAuction(id, price)
        .then((res) => {
          if (res.data?.redirectUrl) {
            window.location.href = res.data.redirectUrl;
          }
        })
        .catch((error) => {
          console.error('Bid failed:', error);
          setError(
            error.response?.data?.message ||
              'An error occurred while placing the bid.'
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.bidsList}>
        {/* Note: This should be genreated from the auction.valid_bid_amounts */}
        {/* {ValidBidAmounts.map((bid) => (
          <div
            key={bid}
            className={cn(styles.bid, {
              [styles.selected]: selectedBid === bid,
            })}
            onClick={() => setSelectedBid(bid)}
          >
            {bid}
          </div>
        ))} */}
      </div>

      {/* {selectedBid && ( */}
      <div className={styles.titleWrapper}>
        <div className={styles.title}>Total due</div>
        <div className={styles.title}>{price / 100} $</div>
      </div>
      {/* )} */}

      <div className={styles.cancelInfo}>
        <div className={styles.infoWrapper}>
          <div className={styles.cancelTitle}>
            Cancel any time and subscribe with confidence with
          </div>
          <a
            href={PathRoutes.TermsAndConditionsRoute}
            target="_blank"
            rel="noreferrer"
            className={styles.cancelDesc}
          >
            Dunbar148's Circle of Trust Guarantee
          </a>
        </div>
        <div className={styles.letter}>D</div>
      </div>

      {!user && (
        <div className={styles.text}>
          To make a bid, you need to{' '}
          <span
            onClick={() => {
              const redirectUrl = encodeURIComponent(location.pathname);
              setIsOpenModal(true);
              window.history.replaceState(null, '', `?redirect=${redirectUrl}`);
            }}
            className={styles.link}
          >
            log in or sign up.
          </span>
        </div>
      )}

      <MuiModal open={isOpenModal} setOpen={setIsOpenModal}>
        <RegisterModal redirect={location.pathname} />
      </MuiModal>

      {error && <div className={styles.error}>{error}</div>}

      <button
        className={styles.btn}
        disabled={loading || !user}
        onClick={makeBid}
      >
        {loading ? (
          <CircularProgress size={24} style={{ color: 'white' }} />
        ) : (
          'Bid'
        )}
      </button>
    </div>
  );
};
