import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import store from './redux/store/store';
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <div className="main">
      <BrowserRouter>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Toaster
              position="bottom-right"
              toastOptions={{
                duration: 5500,
              }}
            />
            <App />
          </LocalizationProvider>
        </Provider>
      </BrowserRouter>
    </div>
  </React.StrictMode>
);

reportWebVitals();
