import { useEffect, useRef } from 'react';
import Hls from 'hls.js';
import { MuiModal } from 'src/components/material/MuiModal';
import { VideoMessage } from '../../types';
import styles from './ViewResponseModal.module.scss';
import moment from 'moment';

interface ViewResponseModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
  responseVideo: VideoMessage | null;
}

export const ViewResponseModal = ({
  isOpenModal,
  setIsOpenModal,
  responseVideo,
}: ViewResponseModalProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!responseVideo || !responseVideo.video_url || !videoRef.current) {
        return;
      }

      const videoElement = videoRef.current;
      let hls: Hls | null = null;

      if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(responseVideo.video_url);
        hls.attachMedia(videoElement);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          videoElement
            .play()
            .catch(() => console.warn('Autoplay prevented by browser'));
        });
      } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
        videoElement.src = responseVideo.video_url;
        videoElement.load();
        videoElement
          .play()
          .catch(() => console.warn('Autoplay prevented by browser'));
      }

      return () => {
        if (hls) {
          hls.destroy();
        }
        if (videoElement) {
          videoElement.pause();
          videoElement.src = '';
        }
      };
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [responseVideo, isOpenModal]);

  if (!responseVideo || !responseVideo.video_url) {
    return (
      <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={600}>
        <div className={styles.modalContent}>
          <p>No video available to display.</p>
        </div>
      </MuiModal>
    );
  }

  return (
    <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={600}>
      <div className={styles.modalContent}>
        <video
          key={responseVideo.video_url}
          ref={videoRef}
          className={styles.videoPlayer}
          playsInline
        >
          Your browser does not support the video tag.
        </video>
        <div className={styles.challengeOverlay}>
          {responseVideo.msgType === 'REPLY' ? (
            <div className={styles.memberName}>{responseVideo.member_name}</div>
          ) : (
            responseVideo?.leader_name && (
              <div className={styles.memberName}>
                {responseVideo.leader_name}
              </div>
            )
          )}
          {responseVideo?.createdAt && (
            <div className={styles.date}>
              {moment(responseVideo.createdAt).format('MMM D, h:mm A')}
            </div>
          )}
          <div className={styles.videoDetails}>
            {responseVideo?.title && (
              <div className={styles.title}>{responseVideo.title}</div>
            )}
          </div>
        </div>
      </div>
    </MuiModal>
  );
};
