import React from 'react';
import Avatar from '@mui/material/Avatar';
// import CreateIcon from '@mui/icons-material/Create';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import styles from './AvatarComponent.module.scss';
// import { usersApi } from 'src/api/user';
// import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
// import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import {
  selectCircleDetailsData,
  // updateCircle,
} from 'src/redux/slices/circleDetailsSlice/circleDetailsSlice';

interface AvatarComponentProps {
  isLeader: boolean;
}

export const AvatarComponent: React.FC<AvatarComponentProps> = () => {
  // const dispatch = useAppDispatch();
  const circleData = useAppSelector(selectCircleDetailsData);
  const { leader_avatar_url, tribe_avatar_url } = circleData || {};
  const avatarUrl = tribe_avatar_url ? tribe_avatar_url : leader_avatar_url;

  // const [isUploading, setIsUploading] = useState(false);
  // const fileRef = useRef<HTMLInputElement>(null);

  // const handleSave = (key: string, value: string | File) => {
  //   if (typeof value === 'string') {
  //     dispatch(updateCircle({ id: _id || '', updates: { [key]: value } }));
  //   } else {
  //     console.log('Received a File object:', value);
  //   }
  // };

  // const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const uploadedFile = e.target.files?.[0];
  //   if (uploadedFile) {
  //     if (uploadedFile.size <= 4194304) {
  //       setIsUploading(true);
  //       try {
  //         const response = await usersApi.uploadFile({
  //           contentType: uploadedFile.type,
  //           name: 'avatarurl',
  //           type: 'image-avatar',
  //         });
  //         const { uploadURL, Key } = response.data;

  //         const uploadResponse = await fetch(uploadURL, {
  //           method: 'PUT',
  //           headers: {
  //             'Content-Type': uploadedFile.type,
  //           },
  //           body: uploadedFile,
  //         });

  //         if (!uploadResponse.ok) {
  //           throw new Error(
  //             `Upload failed with status: ${uploadResponse.status}`
  //           );
  //         }

  //         const finalUrl = `https://d30r677afi5rfh.cloudfront.net/${Key}`;
  //         setAvatarUrl(finalUrl);
  //         handleSave('tribe_avatar_url', finalUrl);
  //       } catch (error) {
  //         console.error('Error uploading file:', error);
  //         alert('Error uploading file. Check the console for more details.');
  //       } finally {
  //         setIsUploading(false);
  //       }
  //     } else {
  //       alert('File size should not exceed 4MB');
  //     }
  //   }
  // };

  // const handleIconClick = () => {
  //   fileRef.current?.click();
  // };

  return (
    <div className={styles.avatar}>
      {/* {isUploading ? (
        <div className={styles.loader}>
          <MaterialCircularPogress height={'100%'} />
        </div>
      ) : ( */}
      <Avatar
        src={avatarUrl}
        sx={{ width: 300, height: 300, borderRadius: '50%' }}
      />
      {/* )} */}
      {/* {isLeader && (
        <div onClick={handleIconClick}>
          <CreateIcon sx={{ color: 'red', cursor: 'pointer' }} />
        </div>
      )}
      <input
        accept=".jpg, .jpeg, .png"
        type="file"
        style={{ display: 'none' }}
        ref={fileRef}
        onChange={handleInputChange}
      /> */}
    </div>
  );
};
