import { FC, useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
// import styles from '../Videos/VideoItem/VideoItem.module.scss';
import { useMediaQuery } from '@mui/material';
import dunbarWhite from 'src/assets/images/dunbar/dunbar-white.png';
import styles from './WelcomeVideo.module.scss';
import { CircleProps } from '../../conts';

interface WelcomeVideoProps {
  circleData: CircleProps | null;
}

export const WelcomeVideo: FC<WelcomeVideoProps> = ({ circleData }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isMobile = useMediaQuery('(max-width:545px)');
  const [currentPlayingVideo, setCurrentPlayingVideo] =
    useState<HTMLVideoElement | null>(null);
  const welcomeVideoUrl = circleData?.welcome_video_url ?? '';

  useEffect(() => {
    let hls: Hls | undefined;

    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(welcomeVideoUrl);
      hls.attachMedia(videoRef.current!);
    } else if (videoRef.current?.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = welcomeVideoUrl;
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [welcomeVideoUrl]);

  const handleMouseEnter = () => {
    if (!isMobile && videoRef.current) {
      if (currentPlayingVideo && currentPlayingVideo !== videoRef.current) {
        currentPlayingVideo.pause();
      }

      videoRef.current.play();
      setCurrentPlayingVideo(videoRef.current);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile && videoRef.current) {
      videoRef.current.pause();
    }
  };

  const calculateRelativeTime = (date: string) => {
    const createdAt = new Date(date);

    const formattedDate = `${createdAt.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
    })}, ${createdAt.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })}`;

    return formattedDate;
  };

  const relativeTime = calculateRelativeTime(circleData?.createdAt || '');

  const displayedChallenges = circleData?.habits?.slice(0, 2) ?? [];
  const moreChallengesCount =
    (circleData?.habits?.length ?? 0) - displayedChallenges.length;

  const truncateText = (
    text: string | undefined,
    maxLength: number
  ): string => {
    if (!text) return '';
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  return (
    <div
      className={styles.videoContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <video ref={videoRef} className={styles.video} playsInline>
        Your browser does not support the video tag.
      </video>
      <div className={styles.challengeOverlay}>
        <div className={styles.topData}>
          <div className={styles.circleName}>{circleData?.tribe_name}</div>
          <div className={styles.time}>{relativeTime}</div>
        </div>
        <div className={styles.bottomTitle}>Challenges:</div>
        {displayedChallenges.map((challenge, index) => (
          <div className={styles.challengeItem} key={index}>
            {/* <img
              src={getCategoryImage(challenge.category)}
              alt={challenge.category}
              className={styles.challengeImage}
            /> */}
            <div className={styles.challengeTitle}>
              {truncateText(String(challenge.name), 37)}
            </div>
          </div>
        ))}
        {moreChallengesCount > 0 && (
          <div className={styles.moreItem}>+{moreChallengesCount} more</div>
        )}
        <div className={styles.dunbarIconWrapper}>
          <img src={dunbarWhite} alt="Dunbar" className={styles.dunbarIcon} />
        </div>
        <div className={styles.commentsCount}>
          {/* {item.comments.length} comments */}
        </div>
      </div>
    </div>
  );
};
