import api from './baseApi';

export const circleApi = {
  createCircle: (circleNewData: any) => {
    return api.post(`tribes/`, circleNewData);
  },

  editCircle: (circleNewData: Record<string, any>, id: string) => {
    return api.put(`tribes/${id}`, circleNewData);
  },

  editCirclePatch: (circleNewData: Record<string, any>, id: string) => {
    return api.patch(`tribes/${id}`, circleNewData);
  },

  editUserCount: (id: string, member_count_max: number) => {
    return api.patch(`tribes/${id}/max_members`, { member_count_max });
  },

  getCircle: (id: string) => {
    return api.get(`tribes/${id}`);
  },

  getVideoId: (id: string) => {
    return api.get(`files/${id}`);
  },

  inviteMemberByPhone: (
    tribe_id: string,
    role: string,
    phone_number: string
  ) => {
    return api.post(`tribes/${tribe_id}/members/add`, {
      role,
      phone_number,
    });
  },

  inviteMemberByEmail: (tribe_id: string, role: string, email: string) => {
    return api.post(`tribes/${tribe_id}/members/add`, {
      role,
      email,
    });
  },

  bulkAddMembers: (tribeId: string, data: { csvData: string }) => {
    return api.post(`tribes/${tribeId}/members/bulk-add`, data);
  },

  deleteMember: (tribe_id: string, user_id: string) => {
    return api.put(`tribes/${tribe_id}/leave`, {
      user_id,
    });
  },

  deleteCircle: (id: string) => {
    return api.delete(`tribes/${id}`);
  },

  getMembersOfCircle: (id: string) => {
    return api.get(`tribes/${id}/members`);
  },
};
