import { useEffect } from 'react';
import { SectionComponent } from '../SectionCompnent/SectionCompnent';
import { NAME, PHOTO, BIO, EMAIL } from '../const';
import styles from './InfoProfile.module.scss';

export const InfoProfile = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (!hash) return;

    const id = hash.substring(1);

    const tryScroll = () => {
      const element = document.getElementById(id);
      if (!element) return;

      const rect = element.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      const offset = rect.top + scrollTop - 200;

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    };

    setTimeout(tryScroll, 500);
  }, []);

  return (
    <div className={styles.container}>
      <div>
        <SectionComponent
          isSmallTitle
          title={NAME.title}
          description={NAME.description}
        />
      </div>

      <div>
        <SectionComponent
          isSmallTitle
          title={PHOTO.title}
          description={PHOTO.description}
        />
      </div>
      <div id="info-name" />

      <div>
        <SectionComponent
          isSmallTitle
          title={BIO.title}
          description={BIO.description}
        />
      </div>
      <div id="info-photo" className={styles.test} />
      {/* <div id="info-birthday">
        <SectionComponent
          isSmallTitle
          title={BIRTHDAY.title}
          description={BIRTHDAY.description}
        />
      </div> */}
      <div>
        <SectionComponent
          isSmallTitle
          title={EMAIL.title}
          description={EMAIL.description}
        />
        <div id="info-bio" />
      </div>
    </div>
  );
};
