import styles from './SubInfo.module.scss';
import { FC } from 'react';
import moment from 'moment';

interface SubInfoProps {
  open_seat_count?: number;
  closeDate?: string;
}

export const SubInfo: FC<SubInfoProps> = ({ open_seat_count, closeDate }) => {
  const getTimeLeft = (closeDate?: string) => {
    if (!closeDate) return 'N/A';

    const now = moment();
    const closeTime = moment(closeDate);

    if (!closeTime.isValid()) return 'Invalid date';

    const duration = moment.duration(closeTime.diff(now));

    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours()) % 24;
    const minutes = Math.floor(duration.asMinutes()) % 60;

    if (days > 0) {
      return `${days} days, ${hours} hours left`;
    } else {
      return `${hours} hours, ${minutes} minutes left`;
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.cutdown}>{getTimeLeft(closeDate)}</div>
        <div className={styles.spots}>{open_seat_count} spots available</div>
      </div>
      <button className={styles.btn}>
        <a href="/how-it-works#faq" target="_blank" rel="noopener noreferrer">
          How it works
        </a>
      </button>
    </div>
  );
};
