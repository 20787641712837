import { FC, useState } from 'react';
import { MuiModal } from 'src/components/material/MuiModal';
import styles from './SendResponseModal.module.scss';
import { UploadVideoComponent } from 'src/components/common/UploadVideo/UploadVideo';
import MaterialTextField from 'src/components/material/textField';
import { messagesApi } from 'src/api/messagesApi';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import {
  addVideoMessage,
  addReplyMessage,
} from 'src/redux/slices/circleDetailsSlice/circleDetailsSlice';
import toast from 'react-hot-toast';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import { startPollingForVideoUrl } from './helpers';

interface SendResponseModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
  leaderId: string;
  leaderName: string;
  circleId: string;
  memberName: string;
  memberId: string;
  messageId?: string;
  isLeader?: boolean;
}

const MAX_CHARACTERS = 60;

export const SendResponseModal: FC<SendResponseModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  leaderId,
  leaderName,
  circleId,
  memberName,
  memberId,
  messageId,
  isLeader,
}) => {
  const dispatch = useAppDispatch();

  const [videoId, setVideoId] = useState('');
  const [videoError, setVideoError] = useState('');
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleVideoUpdate = (fileId: string | File) => {
    if (typeof fileId === 'string') {
      setVideoId(fileId);
      setVideoError('');
    } else {
      console.log('Received a File object:', fileId);
    }
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.length <= MAX_CHARACTERS) {
      setMessage(inputValue);
      if (inputValue.trim() !== '') {
        setMessageError('');
      }
    }
  };

  const handleSendMessage = async () => {
    let hasError = false;

    if (!videoId) {
      setVideoError('Please upload a video before sending.');
      hasError = true;
    }

    if (!message.trim()) {
      setMessageError('Title is required.');
      hasError = true;
    }

    if (hasError) return;

    setIsLoading(true);

    const requestData = {
      tribe_id: circleId,
      leader_id: leaderId,
      leader_name: leaderName,
      member_id: memberId,
      member_name: memberName,
      video_id: videoId,
      title: message,
    };

    try {
      let res;
      if (isLeader) {
        res = await messagesApi.sendReplyFromLeader(requestData);
      } else {
        if (!messageId) {
          console.error('Error: messageId is required for member replies.');
          return;
        }
        res = await messagesApi.sendReplyFromMember(messageId, requestData);
      }
      const newMessage = res.data.result;

      if (isLeader) {
        dispatch(addVideoMessage(newMessage));
      } else {
        dispatch(addReplyMessage(newMessage));
      }

      toast.success(
        'We are uploading your video. Sometimes it can take a few minutes. Come back later!'
      );

      setIsOpenModal(false);
      setVideoId('');
      setMessage('');
      setIsLoading(false);
      startPollingForVideoUrl(videoId, circleId, dispatch, !!isLeader);
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Something went wrong. Please try again.');
    }
  };

  return (
    <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={400}>
      {isLoading && (
        <div className={styles.loaderOverlay}>
          <MaterialCircularPogress />
        </div>
      )}

      {!isLoading && (
        <div className={styles.content}>
          <div className={styles.title}>Send Message</div>

          <UploadVideoComponent
            defaultVideo={''}
            onUpdate={handleVideoUpdate}
            helperText="Upload a video (max 60 seconds)"
          />
          {videoError && <div className={styles.error}>{videoError}</div>}

          <MaterialTextField
            variant="outlined"
            color="primary"
            placeholder="Enter a title for your message"
            value={message}
            onChange={handleMessageChange}
            error={!!messageError}
            fullWidth
          />
          <div className={styles.charactersLength}>
            {message.length}/{MAX_CHARACTERS}
          </div>
          {messageError && <div className={styles.error}>{messageError}</div>}

          <button className={styles.btn} onClick={handleSendMessage}>
            Send Message
          </button>
        </div>
      )}
    </MuiModal>
  );
};
