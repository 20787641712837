import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { themeColors, themeTypography } from './theme';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
  // useParams,
} from 'react-router-dom';
import { Login } from 'src/pages/Login/Login';
import { Home } from 'src/pages/Home/Home';
import './amplifyconfiguration';
import useUserSession from 'src/hooks/UserSession/UserSession';
import ProtectedRoute from './wrappers/ProtectedRoute';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import MainTemplate from './templates/Main';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import { SignUp } from 'src/pages/SignUp/SignUp';
import { ForgetPassword } from 'src/pages/ForgetPassword/ForgetPassword';
import { Sidebar } from './components/common/Sidebar/Sidebar';
import { Auction } from './pages/Auction/Auction';
import { CreateProfile } from './pages/CreateProfile/CreateProfile';
import { CreateCircle } from './pages/CreateCircle/CreateCircle';
import { HowItWorks } from './pages/HowItWorks/HowItWorks';

import { useEffect } from 'react';
import { UserCircle } from './pages/UserCircle/UserCircle';
import { useAppDispatch } from './hooks/Redux/useAppDispatch';
import { EditProfile } from './pages/EditProfile/EditProfile';
import { EditCircle } from './pages/EditCircle/EditCircle';
import { AdminPage } from './pages/AdminPage/AdminPage';
import { UserPage } from './pages/UserPage/UserPage';
import { FeedPage } from './pages/FeedPage/FeedPage';
import {
  fetchLoggedUser,
  selectLoggedUserInitialized,
} from './redux/slices/loggedUserSlice';
// import { FeedPageSecond } from './pages/FeedPageSecond/feedPageSecond';
import { ChallengePage } from './pages/ChallengePage/ChallengePage';
import useAppSelector from './hooks/Redux/useAppSelector';
import { currentUser } from './redux/slices/userSlice';
import { TermsAndCondition } from './pages/TermsAndCondition/TermsAndCondition';
const theme = createTheme({
  palette: { ...themeColors },
  typography: { ...themeTypography },
});

export const App = () => {
  const { loading, user } = useUserSession();
  const loggedUserInitialized = useAppSelector(selectLoggedUserInitialized);

  const getCurrentUser = useAppSelector(currentUser);

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const shouldDisplaySidebar = ![
    PathRoutes.LoginRoute,
    PathRoutes.SignUpRoute,
    PathRoutes.ForgetPasswordRoute,
    PathRoutes.CreateProfileRoute,
    // PathRoutes.CreateCircleRoute,
    // PathRoutes.EditProfileRoute,
    // PathRoutes.EditCircleRoute,
  ].includes(location.pathname);

  useEffect(() => {
    if (!loggedUserInitialized && getCurrentUser) {
      dispatch(fetchLoggedUser());
      // navigate(PathRoutes.HomeRoute);
    }
  }, [dispatch, loggedUserInitialized, getCurrentUser, navigate]);

  if (!loggedUserInitialized && getCurrentUser !== null) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <MaterialCircularPogress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {shouldDisplaySidebar && <Sidebar />}
      {loading ? (
        <MaterialCircularPogress />
      ) : (
        <Routes>
          <Route element={<ProtectedRoute user={user} />}>
            <Route element={<MainTemplate />}>
              <Route path={PathRoutes.HomeRoute} element={<Home />} />
            </Route>
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.AuctionRoute} element={<Auction />} />
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.FeedPageRoute} element={<FeedPage />} />
            {/* <Route
              path={PathRoutes.FeedPageRoute}
              element={<FeedPageSecond />}
            /> */}
          </Route>
          <Route element={<MainTemplate />}>
            <Route
              path={PathRoutes.ChallengePageRoute}
              element={<ChallengePage />}
            />
          </Route>
          <Route element={<MainTemplate />}>
            <Route
              path={PathRoutes.TermsAndConditionsRoute}
              element={<TermsAndCondition />}
            />
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.AdminPageRoute} element={<AdminPage />} />
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.UserPageRoute} element={<UserPage />} />
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.UserCircle} element={<UserCircle />} />
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.HowItWorks} element={<HowItWorks />} />
          </Route>
          <Route
            path={PathRoutes.LoginRoute}
            element={user ? <Navigate to={PathRoutes.HomeRoute} /> : <Login />}
          />
          <Route path={PathRoutes.SignUpRoute} element={<SignUp />} />
          <Route
            path={PathRoutes.ForgetPasswordRoute}
            element={<ForgetPassword />}
          />
          <Route path="/" element={<Navigate to="/profile" replace />} />
          <Route
            path={PathRoutes.CreateProfileRoute}
            element={<CreateProfile />}
          />
          <Route element={<MainTemplate />}>
            <Route
              path={PathRoutes.EditProfileRoute}
              element={<EditProfile />}
            />
          </Route>
          <Route element={<MainTemplate />}>
            <Route
              path={PathRoutes.CreateCircleRoute}
              element={<CreateCircle />}
            />
          </Route>
          <Route element={<MainTemplate />}>
            <Route path={PathRoutes.EditCircleRoute} element={<EditCircle />} />
          </Route>
        </Routes>
      )}
    </ThemeProvider>
  );
};
