import { FC, Key, useEffect, useRef, useState } from 'react';
// import toast from 'react-hot-toast';
import Hls from 'hls.js';
// import CreateIcon from '@mui/icons-material/Create';
import { useMediaQuery } from '@mui/material';
// import classNames from 'classnames';
import dunbarWhite from 'src/assets/images/dunbar/dunbar-white.png';
import styles from './WelcomeVideo.module.scss';
// import { usersApi } from 'src/api/user';
// import { circleApi } from 'src/api/circles';
// import { useDispatch } from 'react-redux';
// import { AppDispatch } from 'src/redux/store/store';
import {
  TribeDetail,
  // updateCircle,
} from 'src/redux/slices/circleDetailsSlice/circleDetailsSlice';

interface WelcomeVideoProps {
  circleData: TribeDetail | null;
  isLeader: boolean;
}

export const WelcomeVideo: FC<WelcomeVideoProps> = ({
  circleData,
  // isLeader,
}) => {
  // const dispatch = useDispatch<AppDispatch>();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  // const fileRef = useRef<HTMLInputElement | null>(null);
  const isMobile = useMediaQuery('(max-width:545px)');
  const [isLoading, setIsLoading] = useState(true);
  const [currentPlayingVideo, setCurrentPlayingVideo] =
    useState<HTMLVideoElement | null>(null);
  // const [isUploading, setIsUploading] = useState(false);
  // const [previewVideoUrl, setPreviewVideoUrl] = useState<string | null>(null);

  // const welcomeVideoUrl = isUploading
  //   ? (previewVideoUrl ?? '')
  //   : (circleData?.welcome_video_url ?? '');
  // const welcomeVideoUrl = isUploading
  //   ? (previewVideoUrl ?? '')
  //   : (circleData?.welcome_video_url ?? '');

  const welcomeVideoUrl = circleData?.welcome_video_url ?? '';

  useEffect(() => {
    let hls: Hls | undefined;
    const video = videoRef.current;

    const handleCanPlay = () => setIsLoading(false);
    const handleWaiting = () => setIsLoading(true);
    const handleLoadedData = () => setIsLoading(false);

    if (video) {
      video.addEventListener('canplay', handleCanPlay);
      video.addEventListener('waiting', handleWaiting);
      video.addEventListener('loadeddata', handleLoadedData);

      if (welcomeVideoUrl.startsWith('blob:')) {
        video.src = welcomeVideoUrl;
      } else if (Hls.isSupported() && welcomeVideoUrl) {
        hls = new Hls();
        hls.loadSource(welcomeVideoUrl);
        hls.attachMedia(video);
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = welcomeVideoUrl;
      }
    }

    return () => {
      if (video) {
        video.removeEventListener('canplay', handleCanPlay);
        video.removeEventListener('waiting', handleWaiting);
        video.removeEventListener('loadeddata', handleLoadedData);
      }
      if (hls) {
        hls.destroy();
      }
    };
  }, [welcomeVideoUrl]);

  useEffect(() => {
    let hls: Hls | undefined;

    if (welcomeVideoUrl.startsWith('blob:')) {
      videoRef.current!.src = welcomeVideoUrl;
    } else if (Hls.isSupported() && welcomeVideoUrl) {
      hls = new Hls();
      hls.loadSource(welcomeVideoUrl);
      hls.attachMedia(videoRef.current!);
    } else if (videoRef.current?.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = welcomeVideoUrl;
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [welcomeVideoUrl]);

  const handleMouseEnter = () => {
    if (!isMobile && videoRef.current) {
      if (currentPlayingVideo && currentPlayingVideo !== videoRef.current) {
        currentPlayingVideo.pause();
      }

      videoRef.current.play();
      setCurrentPlayingVideo(videoRef.current);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile && videoRef.current) {
      videoRef.current.pause();
    }
  };

  // const handleUploadClick = () => {
  //   fileRef.current?.click();
  // };

  // const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0];
  //   if (!file || !circleData?._id) return;

  //   if (file.size > 100 * 1024 * 1024) {
  //     alert('Video size should not exceed 100MB');
  //     return;
  //   }

  //   setIsUploading(true);
  //   try {
  //     const response = await usersApi.uploadFile({
  //       contentType: file.type,
  //       name: file.name,
  //       type: 'video-welcome',
  //     });

  //     const { uploadURL, Key } = response.data;

  //     await fetch(uploadURL, {
  //       method: 'PUT',
  //       headers: { 'Content-Type': file.type },
  //       body: file,
  //     });

  //     const getVideoId = await circleApi.getVideoId(Key);
  //     const newVideoId = getVideoId.data._id;

  //     const previewUrl = URL.createObjectURL(file);
  //     setPreviewVideoUrl(previewUrl);

  //     await dispatch(
  //       updateCircle({
  //         id: circleData._id,
  //         updates: {
  //           welcome_video_id: newVideoId,
  //         },
  //       })
  //     );
  //     toast.success(
  //       'We are uploading your video. Sometimes it can take a few minutes. Come back later!'
  //     );
  //   } catch (error) {
  //     console.error('Error uploading video:', error);
  //     alert('Error uploading video. Please try again.');
  //   } finally {
  //     setIsUploading(false);
  //   }
  // };

  const calculateRelativeTime = (date: string) => {
    const createdAt = new Date(date);

    const formattedDate = `${createdAt.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
    })}, ${createdAt.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })}`;

    return formattedDate;
  };

  const relativeTime = calculateRelativeTime(circleData?.createdAt || '');

  const displayedChallenges = circleData?.habits?.slice(0, 2) ?? [];
  const moreChallengesCount =
    (circleData?.habits?.length ?? 0) - displayedChallenges.length;

  const truncateText = (
    text: string | undefined,
    maxLength: number
  ): string => {
    if (!text) return '';
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  return (
    //   <div className={styles.uploadingOverlay}>
    //     <MaterialCircularPogress />
    //   </div>
    // ) : (
    <div className={styles.container}>
      <div
        // className={classNames(styles.videoContainer, {
        //   [styles.uploading]: isUploading,
        // })}
        className={styles.videoContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={styles.videoWrapper}>
          {isLoading && (
            <div className={styles.loaderOverlay}>
              <div className={styles.loaderSpinner}></div>
            </div>
          )}
          {/* {isUploading && (
            <div className={styles.loaderOverlay}>
              <div className={styles.loaderSpinner}></div>
            </div>
          )} */}
          <video ref={videoRef} className={styles.video} playsInline>
            Your browser does not support the video tag.
          </video>
        </div>

        <div className={styles.challengeOverlay}>
          <div className={styles.topData}>
            <div className={styles.circleName}>{circleData?.tribe_name}</div>
            <div className={styles.time}>{relativeTime}</div>
          </div>
          <div className={styles.bottomTitle}>Challenges:</div>
          {displayedChallenges.map(
            (challenge: { name: any }, index: Key | null | undefined) => (
              <div className={styles.challengeItem} key={index}>
                <div className={styles.challengeTitle}>
                  {truncateText(String(challenge.name), 37)}
                </div>
              </div>
            )
          )}
          {moreChallengesCount > 0 && (
            <div className={styles.moreItem}>+{moreChallengesCount} more</div>
          )}
          <div className={styles.dunbarIconWrapper}>
            <img src={dunbarWhite} alt="Dunbar" className={styles.dunbarIcon} />
          </div>
        </div>
      </div>

      {/* {isLeader && (
        <>
          <CreateIcon
            sx={{ color: 'red', cursor: 'pointer', mt: 1 }}
            onClick={handleUploadClick}
          />
          <input
            type="file"
            ref={fileRef}
            style={{ display: 'none' }}
            accept="video/*"
            onChange={handleFileChange}
          />
        </>
      )} */}
    </div>
  );
};
