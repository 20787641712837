import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TabsState {
  activeTabIndex: number | null;
}

const initialState: TabsState = {
  activeTabIndex: null,
};

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveTabIndex: (state, action: PayloadAction<number>) => {
      state.activeTabIndex = action.payload;
    },
    resetTabIndex: (state) => {
      state.activeTabIndex = null;
    },
  },
});

export const { setActiveTabIndex, resetTabIndex } = tabsSlice.actions;
export default tabsSlice.reducer;
