import api, { apiNoAuth } from './baseApi';

export const auctionApi = {
  createAuction: (circleId: string, auctionData: any) => {
    return api.post(`tribes/${circleId}/auctions`, auctionData);
  },

  getAuctionList: (id: string) => {
    return api.get(`tribes/${id}/auctions`);
  },

  getPublicAuctionData: (id: string) => {
    return apiNoAuth.get(`auctions/${id}/public`);
  },

  getSpecificAuction: (id: string) => {
    return api.get(`auctions/${id}`);
  },

  bidAuction: (auctionId: string, bid_amount_usd: number) => {
    return api.post(`auctions/${auctionId}/bids`, { bid_amount_usd });
  },
};
