import api from './baseApi';

export const messagesApi = {
  postComment: (postId: string, data: any) => {
    return api.post(`posts/${postId}/comments`, data);
  },

  getMessagesMember: () => {
    return api.get(`messages/member`);
  },

  getMessagesLeader: (tribeId: string) =>
    api.get(`messages/leader`, {
      params: {
        tribe_id: tribeId,
        // page,
        // limit,
      },
    }),

  sendReplyFromMember: (id: string, data: any) => {
    return api.post(`messages/${id}/reply`, data);
  },

  sendReplyFromLeader: (data: any) => {
    return api.post(`messages`, data);
  },
};
