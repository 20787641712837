import { useState, useRef, FC, MutableRefObject, ReactNode } from 'react';
import styles from './AccordionItem.module.scss';
import addSquare from 'src/assets/icons/accordion/addSquare.svg';
import closeSquare from 'src/assets/icons/accordion/closeSquare.svg';

interface FAQ {
  question: string | JSX.Element;
  answer?: ReactNode;
}

interface AccordionItemProps {
  faq: FAQ;
}

const AccordionItem: FC<AccordionItemProps> = ({ faq }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const contentEl = useRef<HTMLDivElement | null>(
    null
  ) as MutableRefObject<HTMLDivElement>;

  const { question, answer } = faq;

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <div>
          <div className={styles.button} onClick={handleToggle}>
            {question}
            {answer && (
              <span className={styles.pointer}>
                {isOpen ? (
                  <img src={closeSquare} alt="add square" />
                ) : (
                  <img src={addSquare} alt="close square" />
                )}{' '}
              </span>
            )}
          </div>
          {answer && (
            <div
              ref={contentEl}
              className={styles.answerWrapper}
              style={
                isOpen
                  ? { height: 'auto' }
                  : { height: '0px', overflow: 'hidden' }
              }
            >
              <div className={styles.answer}>{answer}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
