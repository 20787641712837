import { CircularProgress, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { circleApi } from 'src/api/circles';
import Habits from 'src/components/common/Habits/Habits';
import { UploadImage } from 'src/components/common/UploadIamge/UploadImage';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import { useAppDispatch } from 'src/hooks/Redux/useAppDispatch';
import { fetchLoggedUser } from 'src/redux/slices/loggedUserSlice';
import { ChooseCategory } from './Category/Category';
import { CommonField } from './CommonField/CommonField';
import styles from './EditCircle.module.scss';
import { Header } from './Header/Header';
import Members from './Members/Members';
import { UploadVideo } from './UploadVideo/UploadVideo';
import { DESCRIPTION_FIELD, NAME_FIELD } from './const';

interface FormData {
  tribe_name: string;
  categories: string[];
  video: string;
  description: string;
  member_count_max: number;
  habits: { name: string; category: string }[];
  membership_type: string;
  welcome_video_id: string;
  tribe_avatar_url: string;
  welcome_video_url: string;
}

export const EditCircle: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const [initialData, setInitialData] = useState<FormData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    tribe_name: '',
    categories: [],
    video: '',
    description: '',
    member_count_max: 1,
    habits: [],
    membership_type: 'FIXED',
    welcome_video_id: '',
    tribe_avatar_url: '',
    welcome_video_url: '',
  });

  useEffect(() => {
    if (id) {
      setLoading(true);
      circleApi
        .getCircle(id)
        .then((res) => {
          const circleData = res.data.result;
          console.log(circleData, 'circleData');
          const formattedData = {
            ...circleData,
            habits: circleData.habits.map((habit: any) => ({
              id: habit._id,
              name: habit.name,
              category: habit.category,
            })),
          };
          setFormData(formattedData);
          setInitialData(formattedData);
        })
        .catch(() => {
          setError('Failed to fetch circle data.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const [error, setError] = useState<string>('');

  const handleInputChange = useCallback(
    (field: keyof FormData) =>
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData((prevData) => ({
          ...prevData,
          [field]: event.target.value,
        }));
      },
    []
  );

  const handleSelectChange = useCallback(
    (field: keyof FormData) => (event: SelectChangeEvent) => {
      setFormData((prevData) => ({
        ...prevData,
        [field]: Number(event.target.value),
      }));
    },
    []
  );

  const handleCategoryClick = useCallback((category: string) => {
    setFormData((prevData) => {
      const isSelected = prevData.categories.includes(category);
      let updatedCategories: string[] = [];

      if (isSelected) {
        updatedCategories = prevData.categories.filter(
          (cat) => cat !== category
        );
      } else if (prevData.categories.length < 4) {
        updatedCategories = [...prevData.categories, category];
      } else {
        updatedCategories = prevData.categories;
      }

      return {
        ...prevData,
        categories: updatedCategories,
      };
    });
  }, []);

  // const handleSubscriptionChange = useCallback((type: string) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     membership_type: type,
  //   }));
  // }, []);

  const handleHabitsChange = useCallback(
    (newHabits: { name: string; category: string }[]) => {
      setFormData((prevData) => ({
        ...prevData,
        habits: newHabits,
      }));
    },
    []
  );

  const handleVideoUpdate = (fileId: string | File) => {
    if (typeof fileId === 'string') {
      setFormData({ ...formData, welcome_video_id: fileId });
    } else {
      console.log('Received a File object:', fileId);
    }
  };

  const handleImageUpdate = (fileId: string | File) => {
    console.log(fileId, '123');
    if (typeof fileId === 'string') {
      setFormData((prevData) => ({
        ...prevData,
        tribe_avatar_url: fileId,
      }));
    } else {
      console.log('Received a File object:', fileId);
    }
  };

  const validateForm = useCallback((): boolean => {
    const {
      tribe_name,
      categories,
      welcome_video_id,
      description,
      member_count_max,
      habits,
    } = formData;

    if (
      !tribe_name ||
      categories.length === 0 ||
      !welcome_video_id ||
      !description ||
      member_count_max === 0 ||
      habits.length === 0
    ) {
      setError('All fields are required');
      return false;
    }

    setError('');
    return true;
  }, [formData]);

  const getChangedFields = (initial: FormData, current: FormData) => {
    const changedFields: Partial<FormData> = {};

    (Object.keys(current) as (keyof FormData)[]).forEach((key) => {
      if (JSON.stringify(initial[key]) !== JSON.stringify(current[key])) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        changedFields[key] = current[key];
      }
    });

    return changedFields;
  };

  const editCircle = useCallback(() => {
    if (!validateForm()) return;
    if (!initialData) return;

    const changedFields = getChangedFields(initialData, formData);

    setIsSaving(true);

    circleApi
      .editCirclePatch(changedFields, id)
      .then((res) => {
        setTimeout(() => {
          dispatch(fetchLoggedUser());
          navigate(`/circle/${id}`);
        }, 7000);
      })
      .catch(() => {
        setIsSaving(false);
      });
  }, [formData, validateForm, id, navigate, initialData, dispatch]);

  if (loading) {
    return (
      <div className={styles.loader}>
        <MaterialCircularPogress />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Header id={id} />
        <CommonField
          title={NAME_FIELD.title}
          description={NAME_FIELD.description}
          placeholder={NAME_FIELD.placeholder}
          charactersLength={NAME_FIELD.charactersLength}
          anhor={'info-circle-name'}
          value={formData.tribe_name}
          setValue={handleInputChange('tribe_name')}
        />
        <ChooseCategory
          selectedCategory={formData.categories}
          handleCategoryClick={handleCategoryClick}
        />
        <UploadVideo
          defaultVideo={formData.welcome_video_url}
          onUpdate={handleVideoUpdate}
          helperText="Upload a video (max 60 seconds)"
        />
        <UploadImage
          onUpdate={handleImageUpdate}
          defaultImage={
            typeof formData.tribe_avatar_url === 'string'
              ? formData.tribe_avatar_url
              : ''
          }
        >
          Add Photo
        </UploadImage>
        <CommonField
          type="textarea"
          title={DESCRIPTION_FIELD.title}
          description={DESCRIPTION_FIELD.description}
          placeholder={DESCRIPTION_FIELD.placeholder}
          charactersLength={DESCRIPTION_FIELD.charactersLength}
          anhor={'info-description'}
          value={formData.description}
          setValue={handleInputChange('description')}
        />
        <Members
          value={formData.member_count_max}
          setValue={handleSelectChange('member_count_max')}
        />
        <Habits
          description="“Habits” describe the daily activities and general mindset that lead to success for your Circle’s purpose."
          onHabitsChange={handleHabitsChange}
          habitsArr={formData.habits}
        />
        {/* <Subscription
          subscriptionType={formData.membership_type}
          onSubscriptionChange={handleSubscriptionChange}
          membersCount={formData.member_count_max}
        /> */}
        {error && (
          <Typography
            color="error"
            variant="body2"
            sx={{ mb: 1, mt: 1, textAlign: 'center' }}
          >
            {error}
          </Typography>
        )}
        <button className={styles.btn} onClick={editCircle} disabled={isSaving}>
          {isSaving ? (
            <CircularProgress size={24} sx={{ color: '#fff' }} />
          ) : (
            'Save Circle'
          )}
        </button>
      </div>
    </div>
  );
};
