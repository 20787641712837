import { FC } from 'react';
import styles from '../UserInfo.module.scss';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { getTimeLeft } from './utils';
import moment from 'moment';
import classNames from 'classnames';
import { AuctionBidsInfo } from '../../conts';
import toast from 'react-hot-toast';

interface AuctionSectionProps {
  auctionId: string;
  tribeId: string;
  tribeName: string;
  leaderName: string;
  tribeAvatar: string;
  auctionData: any;
  isMobile?: boolean;
  auctionBidsInfo: AuctionBidsInfo | null;
}

export const AuctionSection: FC<AuctionSectionProps> = ({
  auctionId,
  auctionData,
  isMobile,
  auctionBidsInfo,
}) => {
  const navigate = useNavigate();
  const auctionLink = `https://staging.dunbar-app.com/auction/${auctionId}`;

  const { closeDate, open_seat_count, createdAt } = auctionData;

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(auctionLink)
      .then(() => {
        toast.success('Link copied to clipboard!');
      })
      .catch(() => {
        toast.error('Failed to copy link.');
      });
  };

  const handleGoToAuction = () => {
    if (auctionId) {
      navigate(`/auction/${auctionId}`);
    } else {
      alert('Auction ID not available');
    }
  };

  if (!auctionId) return null;

  const formattedCreatedAt = createdAt
    ? moment(createdAt).format('DD MMM')
    : 'N/A';
  const formattedCloseDate = closeDate
    ? moment(closeDate).format('DD MMM')
    : 'N/A';

  return (
    <div
      className={classNames(styles.auction, {
        [styles.mobileAuction]: isMobile,
      })}
    >
      <div className={styles.auctionInfoWrapper}>
        <div>
          Auction: <span className={styles.text}>{getTimeLeft(closeDate)}</span>
        </div>
        <div className={styles.moneyInfo}>
          <div className={styles.blackBlock}>
            {auctionBidsInfo?.totalBids} bids
          </div>
          <div className={styles.blackBlock}>
            Highest Bid: ${' '}
            {auctionBidsInfo?.highestBid != null
              ? `$${auctionBidsInfo.highestBid / 100}/mth`
              : 'N/A'}
          </div>
        </div>
        <div className={styles.timeWrapper}>
          <div>
            Opened:<span className={styles.text}> {formattedCreatedAt},</span>
          </div>
          <div>
            Closed: <span className={styles.text}>{formattedCloseDate}</span>
          </div>
        </div>
        <div>
          Seats:<span className={styles.text}>{open_seat_count} </span>
        </div>
      </div>
      <div>
        <div className={styles.auctionLink}>
          <div className={styles.auctionLinkWrapper}>
            <div className={styles.linkMobile}>
              {auctionLink.length > 47
                ? `${auctionLink.slice(0, 42)}...`
                : auctionLink}
            </div>
            <div onClick={handleCopyLink}>
              <ContentCopyIcon sx={{ cursor: 'pointer', color: 'black' }} />
            </div>
          </div>
        </div>
        <button className={styles.btn} onClick={handleGoToAuction}>
          View Public Auction
        </button>
      </div>
    </div>
  );
};
