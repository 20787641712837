import { FC } from 'react';
import styles from '../UserInfo.module.scss';
import useAppSelector from 'src/hooks/Redux/useAppSelector';

interface UserStatsProps {
  membersCount: number;
}

export const UserStats: FC<UserStatsProps> = ({ membersCount }) => {
  const { posts } = useAppSelector((state: any) => state.feed);

  return (
    <div className={styles.container_item}>
      <div className={styles.info}>
        {membersCount} <span>Members</span>
      </div>
      <div className={styles.info}>
        {posts.length} <span>Posts</span>
      </div>
    </div>
  );
};
