import React from 'react';
import classNames from 'classnames';

import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';

import './PhoneInput.scss';

export const DunbarPhoneInput = (props: any) => {
  const inputClassName = props.helperText ? 'phone-input-error' : 'phone-input';
  return (
    <>
      <div
        className={classNames(
          'phone-input-container',
          props.containerCustomClass
        )}
      >
        <div className="title">
          {props.title}
          {props.isRequired && <span className="required"> *</span>}
        </div>
        <PhoneInput
          country="us"
          {...props}
          enableLongNumbers={11}
          containerClass={inputClassName}
          disabled={props.disabled}
          //   enableSearch
        />
        {props.error && (
          <div className="error-value">
            {/* <img src={RedCross} alt="red-cross-icon" /> */}
            <span>{props.error}</span>
          </div>
        )}
      </div>
    </>
  );
};

//***please check react-phone-input-2 doc for props
