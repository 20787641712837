import { CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { FC, useCallback, useEffect, useState } from 'react';
import { circleApi } from 'src/api/circles';
import { MuiModal } from 'src/components/material/MuiModal';
import { SendResponseModal } from 'src/pages/Home/Tabs/Modals/SendResponseModal/SendResponseModal';
import { AddMember } from './AddMember/AddMember';
import { BulkAddMembersModal } from './AddMember/BulkAddMember';
// import { ChangeMembersCount } from './AddMember/ChangeMembersCount/ChangeMembersCount';
import styles from './AddMembersModal.module.scss';
interface AddMembersModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (open: boolean) => void;
  circleId: string;
  tribeName: string;
  isLeader: boolean;
  leaderId: string;
  leaderName: string;
  account_type: string;
}

interface Member {
  avatar_url: string;
  bio: string;
  full_name: string;
  id: string;
  role: string;
  tribes: Array<string>;
}

export const AddMembersModal: FC<AddMembersModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  circleId,
  tribeName,
  isLeader,
  leaderId,
  leaderName,
  account_type,
}) => {
  const [showAddMember, setShowAddMember] = useState<boolean>(false);
  const [showBulkAddMembers, setShowBulkAddMembers] = useState<boolean>(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const [isOpenSendModal, setIsOpenSendModal] = useState(false);

  const fetchMembers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await circleApi.getMembersOfCircle(circleId);
      setMembers(response.data.result);
    } catch (error) {
      console.error('Failed to fetch members:', error);
    } finally {
      setLoading(false);
    }
  }, [circleId]);

  useEffect(() => {
    if (isOpenModal) {
      fetchMembers();
    }
  }, [isOpenModal, circleId, fetchMembers]);

  const handleOpenSendModal = (member: Member) => {
    setSelectedMember(member);
    setIsOpenSendModal(true);
  };

  return (
    <MuiModal open={isOpenModal} setOpen={setIsOpenModal} width={300}>
      <div>
        {showAddMember ? (
          <AddMember
            setShowAddMember={setShowAddMember}
            circleId={circleId}
            fetchMembers={fetchMembers}
          />
        ) : showBulkAddMembers ? (
          <BulkAddMembersModal
            setShowBulkAddMembers={setShowBulkAddMembers}
            circleId={circleId}
            fetchMembers={fetchMembers}
          />
        ) : (
          <div>
            <div className={styles.title}>Members of {tribeName}</div>
            <div className={styles.userList}>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                members.map((member) => (
                  <div className={styles.wrapper} key={member.id}>
                    <div className={styles.user}>
                      <Avatar
                        src={member.avatar_url}
                        sx={{ width: 45, height: 45 }}
                      >
                        N
                      </Avatar>
                      <div>
                        <div className={styles.userTitle}>
                          {member.full_name}
                        </div>
                      </div>
                    </div>
                    <div className={styles.iconsWrapper}>
                      <button
                        className={styles.btn}
                        onClick={() => handleOpenSendModal(member)}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
            {isLeader && account_type !== 'AUCTION' && (
              <div className={styles.actionButtonsContainer}>
                <button
                  onClick={() => setShowAddMember(true)}
                  className={styles.btn}
                >
                  Add Member
                </button>
                <button
                  onClick={() => setShowBulkAddMembers(true)}
                  className={styles.btn}
                  style={{ marginTop: '10px' }}
                >
                  Bulk Add Members
                </button>
              </div>
            )}
            {/* {account_type !== 'AUCTION' && (
              <div className={styles.members}>
                <ChangeMembersCount />
              </div>
            )} */}
          </div>
        )}
      </div>

      {selectedMember && (
        <SendResponseModal
          isOpenModal={isOpenSendModal}
          setIsOpenModal={setIsOpenSendModal}
          leaderId={leaderId}
          leaderName={leaderName}
          circleId={circleId}
          memberName={selectedMember.full_name}
          memberId={selectedMember.id}
          isLeader={true}
        />
      )}
    </MuiModal>
  );
};
