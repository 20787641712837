/* eslint-disable react-hooks/exhaustive-deps */

import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useLocation, useNavigate } from 'react-router-dom';
import { postsApi } from 'src/api/posts';
import { CrossIcon } from 'src/assets/icons/challengePage/cross';
import { IconTop } from 'src/assets/icons/challengePage/iconTop';
import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import { CommentsData } from '../UserCircle/conts';
import styles from './ChallengePage.module.scss';
import { CommentsSide } from './CommentsSide/CommentsSide';

export const ChallengePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [comments, setComments] = useState<CommentsData>({
    currentPage: 1,
    result: [],
    totalCommentCount: 0,
    totalPages: 1,
    children: [],
  });
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [currentPost, setCurrentPost] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [posts, setPosts] = useState<any[]>([]);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const urlParts = location.pathname.split('/');
  const circleId = urlParts[2]; // Extract circleId
  const challengeId = urlParts[4];

  // Fetch posts for the circle
  const fetchPosts = async (page: number) => {
    try {
      setLoadingPosts(true);
      const response = await postsApi.getPostsForCircle(circleId, page);
      const { result, totalPages: fetchedTotalPages } = response.data;

      setPosts((prevPosts) => [...prevPosts, ...result]);
      setTotalPages(fetchedTotalPages);
    } catch (error) {
      console.error('Failed to fetch posts:', error);
    } finally {
      setLoadingPosts(false);
    }
  };

  useEffect(() => {
    // Reset posts and fetch the first page when circleId changes
    setPosts([]);
    setCurrentPage(1);
    fetchPosts(1);
  }, [circleId]);

  // Load next page of posts when scrolling or navigating
  useEffect(() => {
    if (
      currentIndex === posts.length - 2 &&
      currentPage < totalPages &&
      !loadingPosts
    ) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchPosts(nextPage);
    }
  }, [currentIndex, posts.length, currentPage, totalPages, loadingPosts]);

  // Set current post and fetch its comments
  useEffect(() => {
    if (posts.length > 0 && challengeId) {
      const post = posts.find((post: any) => post._id === challengeId);
      const index = posts.findIndex((post: any) => post._id === challengeId);

      setCurrentPost(post);
      setCurrentIndex(index);

      if (post) {
        setCommentsLoading(true);
        postsApi
          .getCommentsForSelectedPost(challengeId)
          .then((res: any) => {
            setComments(res.data);
          })
          .catch((error: any) => {
            console.error('Failed to fetch comments:', error);
          })
          .finally(() => {
            setCommentsLoading(false);
          });
      }
    }
  }, [challengeId, posts]);

  const handleNavigation = (direction: 'next' | 'prev') => {
    if (!currentPost) return;

    const currentIndex = posts.findIndex(
      (post: any) => post._id === currentPost._id
    );

    if (direction === 'next' && currentIndex < posts.length - 1) {
      const nextPost = posts[currentIndex + 1];
      navigate(`/circle/${nextPost.tribe_id}/challenge/${nextPost._id}`);
    }

    if (direction === 'prev' && currentIndex > 0) {
      const prevPost = posts[currentIndex - 1];
      navigate(`/circle/${prevPost.tribe_id}/challenge/${prevPost._id}`);
    }
  };

  if (loadingPosts) {
    return (
      <div className={styles.loaderContainer}>
        <MaterialCircularPogress />
      </div>
    );
  }

  if (!currentPost) {
    return <div>Loading post...</div>;
  }

  return (
    <div className={styles.videoContent}>
      <div className={styles.video}>
        <button
          className={styles.cross}
          onClick={() => navigate(`/circle/${circleId}`)}
        >
          <CrossIcon />
        </button>
        <div className={styles.navigationButtons}>
          <button
            onClick={() => handleNavigation('prev')}
            disabled={loadingPosts}
          >
            <IconTop />
          </button>
          <button
            onClick={() => handleNavigation('next')}
            disabled={loadingPosts}
            style={{ transform: 'rotate(90deg)' }}
          >
            <IconTop />
          </button>
        </div>
        <ReactPlayer
          url={currentPost.video_url}
          controls
          playing={false}
          width="100%"
          height="700px"
          style={{ background: 'black' }}
        />
      </div>
      <div className={styles.commentsSection}>
        {commentsLoading ? (
          <div className={styles.loader}>
            <CircularProgress />
          </div>
        ) : (
          <CommentsSide
            commentsData={comments}
            videoId={currentPost._id}
            loading={loadingPosts}
            isChallengePage
          />
        )}
      </div>
    </div>
  );
};
