import { FC } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { MuiSelect } from 'src/components/material/MuiSelect';
import styles from './AuctionTime.module.scss';
import { DURATION_OPTIONS } from 'src/pages/UserCircle/UserInfo/Modals/CreateAuctionModal/const';

interface AuctionTimeProps {
  duration: string;
  setDuration: (value: string) => void;
}

export const AuctionTime: FC<AuctionTimeProps> = ({
  duration,
  setDuration,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>Duration (in hours)</div>
        {/* <div>
          <InfoOutlinedIcon style={{ color: 'red' }} />
        </div> */}
      </div>
      {/* <div className={styles.description}>
        Set the maximum number of Members you wish to admit to your Circle now.
        You can admit more Members later.
      </div> */}
      <div className={styles.wrapper}>
        <MuiSelect
          label="Duration"
          value={duration}
          options={DURATION_OPTIONS}
          onChange={(event: SelectChangeEvent) =>
            setDuration(event.target.value)
          }
          width="100%"
        />
      </div>
      {/* <div className={styles.charactersLength}>
        Choose a number from 1 to 148
      </div> */}
    </div>
  );
};
