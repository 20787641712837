import styles from './TermsAndCondition.module.scss';

export const TermsAndCondition = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Terms and Conditions</h1>

      <p className={styles.effectiveDate}>Effective Date: January 1, 2025</p>

      <p className={styles.intro}>
        Welcome to dunbar148 . By subscribing to our app, you agree to the
        following terms and conditions. Please read them carefully.
      </p>

      <hr className={styles.divider} />

      <h2 className={styles.subtitle}>1. Subscription Agreement</h2>
      <ul className={styles.list}>
        <li>
          1.1 By subscribing, you gain access to our app and its features,
          subject to these Terms and Conditions.
        </li>
        <li>
          1.2 Subscriptions are billed monthly in advance and automatically
          renew each month unless canceled by you.
        </li>
        <li>
          1.3 You acknowledge that it is your responsibility to maintain
          accurate payment information to ensure uninterrupted service.
        </li>
      </ul>

      <hr className={styles.divider} />

      <h2 className={styles.subtitle}>2. Billing and Payment</h2>
      <ul className={styles.list}>
        <li>
          2.1 Subscription fees are billed monthly in advance and are
          non-refundable.
        </li>
        <li>
          2.2 Payment is accepted via credit card only. By subscribing, you
          authorize us to charge your card on file for the recurring fees.
        </li>
        <li>
          2.3 If a payment fails, your access to the app will be suspended until
          the payment information is updated and successfully processed.
        </li>
        <li>2.4 All fees are exclusive of taxes as no taxes are applicable.</li>
      </ul>
    </div>
  );
};
