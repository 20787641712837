import { FC } from 'react';

import styles from '../CommentItem.module.scss';
import { CommentItem } from '../CommentItem';
import { Comment } from 'src/pages/UserCircle/Tabs/Videos/SelectedVideoModal/const';

interface CommentRepliesProps {
  showReplies: boolean;
  localReplies: Comment[];
  toggleReplies: () => void;
  videoId: string;
  onDelete: (commentId: string) => void;
  onEdit: (commentId: string, newText: string) => void;
}

export const CommentReplies: FC<CommentRepliesProps> = ({
  showReplies,
  localReplies,
  toggleReplies,
  videoId,
  onDelete,
  onEdit,
}) => (
  <>
    {/* {localReplies.length > 0 && (
      <button onClick={toggleReplies} className={styles.repliesButton}>
        {showReplies ? 'Hide Replies' : `Show Replies (${localReplies.length})`}
      </button>
    )} */}
    {/* {showReplies && ( */}
    <div className={styles.replies}>
      {localReplies.map((reply) => (
        <CommentItem
          key={reply._id}
          comment={reply}
          replies={[]}
          videoId={videoId}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ))}
    </div>
    {/* )} */}
  </>
);
