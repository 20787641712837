export const NAME_FIELD = {
  title: 'Circle name',
  description:
    'Either use your own name or choose a name that describes the purpose of your Circle.',
  placeholder: 'Circle name',
  charactersLength: 100,
};

export const EMAIL_FIELD = {
  title: 'Email',
  description:
    'Adding your email address helps keep your account secure and will give you another option to retest your password',
  placeholder: 'Your email address',
  charactersLength: 254,
};

export const DESCRIPTION_FIELD = {
  title: 'Description',
  description:
    'Convey to the world who you are and articulate the core values and aspiration of your Circle.',
  placeholder: 'Describe the Circle you will lead',
  charactersLength: 1500,
};
