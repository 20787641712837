import Avatar from '@mui/material/Avatar';
import styles from './UserInfo.module.scss';
import { FC } from 'react';
import { UserInfoProps } from '../const';
import { useNavigate } from 'react-router-dom';
import { PathRoutes } from 'src/const/Routes/PathRoutes';
import useMediaQuery from '@mui/material/useMediaQuery';

export const UserInfo: FC<UserInfoProps> = ({
  result,
  comment_count,
  like_count,
}) => {
  const navigate = useNavigate();

  const matches = useMediaQuery('(max-width:745px)');

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: matches ? `url(${result.avatar_url || ''})` : '',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className={styles.avatar}>
        <Avatar
          src={result ? result.avatar_url : ''}
          sx={{ width: 300, height: 300, borderRadius: '5px' }}
        >
          {result.full_name.charAt(0)}
        </Avatar>
      </div>
      <div className={styles.info}>
        <div className={styles.data}>
          <div className={styles.title}>{result.full_name}</div>
        </div>

        <div>
          <div className={styles.containerMiddle}>
            <div className={styles.infoMiddle}>
              {comment_count} <span>Comments</span>
            </div>
            <div className={styles.infoMiddle}>
              {like_count} <span>Likes</span>
            </div>
          </div>
          <div className={styles.bio}>
            <div>
              <span>{result.bio}</span>
            </div>
          </div>
        </div>
        <div className={styles.btns}>
          <button
            onClick={() => navigate(PathRoutes.EditProfileRoute)}
            className={styles.btn}
          >
            Edit Profile
          </button>
          <button
            className={styles.btn}
            onClick={() => navigate(PathRoutes.CreateCircleRoute)}
          >
            Create Circle
          </button>
        </div>
      </div>
    </div>
  );
};
