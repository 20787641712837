import { FC, useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import { VideoItemProps } from 'src/pages/UserCircle/conts';
import styles from './VideoItem.module.scss';
import { SelectedVideoModal } from '../SelectedVideoModal/SelectedVideoModal';
import dunbarWhite from 'src/assets/images/dunbar/dunbar-white.png';
import { useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export const VideoItem: FC<VideoItemProps> = ({ item }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMediaQuery('(max-width:545px)');
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPlayingVideo, setCurrentPlayingVideo] =
    useState<HTMLVideoElement | null>(null);

  useEffect(() => {
    let hls: Hls | undefined;
    const video = videoRef.current;

    const handleCanPlay = () => setIsLoading(false);
    const handleWaiting = () => setIsLoading(true);
    const handleLoadedData = () => setIsLoading(false);

    if (!video || !item.video_url) return;

    video.addEventListener('canplay', handleCanPlay);
    video.addEventListener('waiting', handleWaiting);
    video.addEventListener('loadeddata', handleLoadedData);

    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(item.video_url);
      hls.attachMedia(video);
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = item.video_url;
    }

    return () => {
      video.removeEventListener('canplay', handleCanPlay);
      video.removeEventListener('waiting', handleWaiting);
      video.removeEventListener('loadeddata', handleLoadedData);
      hls?.destroy();
    };
  }, [item.video_url]);

  const handleVideoClick = () => {
    const circleId = location.pathname.split('/')[2];
    navigate(`/circle/${circleId}/challenge/${item._id}`);
  };

  const handleDunbarClick = () => {
    setIsOpenModal(true);
    videoRef.current?.pause();
  };

  const truncateText = (text: string, maxLength: number) =>
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  const calculateRelativeTime = (date: string) => {
    const createdAt = new Date(date);
    return `${createdAt.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
    })}, ${createdAt.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })}`;
  };

  const displayedChallenges = item.challenges.slice(0, 2);
  const moreChallengesCount =
    item.challenges.length - displayedChallenges.length;
  const relativeTime = calculateRelativeTime(item.createdAt);

  const handleMouseEnter = () => {
    if (!isMobile && videoRef.current) {
      if (currentPlayingVideo && currentPlayingVideo !== videoRef.current) {
        currentPlayingVideo.pause();
      }
      videoRef.current.play();
      setCurrentPlayingVideo(videoRef.current);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile && videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <div
      className={styles.videoContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.videoWrapper}>
        {isLoading && item.video_url && (
          <div className={styles.loaderOverlay}>
            <div className={styles.loaderSpinner}></div>
          </div>
        )}

        {item.video_url ? (
          <video
            ref={videoRef}
            onClick={isMobile ? handleDunbarClick : handleVideoClick}
            className={styles.video}
            playsInline
          >
            Your browser does not support the video tag.
          </video>
        ) : (
          <div className={styles.videoUnavailable}>Video unavailable</div>
        )}
      </div>

      <div className={styles.challengeOverlay}>
        <div className={styles.topData}>
          <div className={styles.circleName}>{item.tribe_name}</div>
          <div className={styles.time}>{relativeTime}</div>
        </div>
        <div className={styles.bottomTitle}>Challenges:</div>
        {displayedChallenges.map((challenge, index) => (
          <div className={styles.challengeItem} key={index}>
            <div className={styles.challengeTitle}>
              {truncateText(challenge.name, 37)}
            </div>
          </div>
        ))}
        {moreChallengesCount > 0 && (
          <div className={styles.moreItem}>+{moreChallengesCount} more</div>
        )}
        <div className={styles.dunbarIconWrapper} onClick={handleDunbarClick}>
          <img src={dunbarWhite} alt="Dunbar" className={styles.dunbarIcon} />
        </div>
        <div className={styles.commentsCount}>
          {item.comments.length} comments
        </div>
      </div>

      <SelectedVideoModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        videoId={item._id}
        videoUrl={item.video_url}
      />
    </div>
  );
};
