import { FC, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './UserInfo.module.scss';
import { TribeDetail } from 'src/pages/Home/const';
import { selectCurrentUser } from 'src/redux/slices/loggedUserSlice';
import { AvatarComponent } from './AvatarComponent/AvatarComponent';
// import { MaterialCircularPogress } from 'src/components/material/CircularPogress';
import { circleApi } from 'src/api/circles';
import useAppSelector from 'src/hooks/Redux/useAppSelector';
import { UserHeader } from './UserHeader/UserHeader';
import { UserStats } from './UserStats/UserStats';
// import { UserCategories } from './UserCategories/UserCategories';
import { UserActions } from './UserActions/UserActions';
import { AuctionSection } from './AuctionSection/AuctionSection';
import { AuctionBidsInfo } from '../conts';
import { selectCircleDetailsData } from 'src/redux/slices/circleDetailsSlice/circleDetailsSlice';

interface UserInfoProps extends TribeDetail {
  auctionId: string;
  auctionData: any;
  auctionBidsInfo: AuctionBidsInfo | null;
}

export const UserInfo: FC<UserInfoProps> = ({
  auctionId,
  auctionData,
  auctionBidsInfo,
}) => {
  const matches = useMediaQuery('(max-width:745px)');
  const circleData = useAppSelector(selectCircleDetailsData);
  const user = useAppSelector(selectCurrentUser);

  const {
    leader_name = '',
    leader_avatar_url = '',
    tribe_name = '',
    leader_id = '',
    _id = '',
    tribe_avatar_url = '',
    // categories = [],
    account_type = '',
    description = '',
  } = circleData || {};
  const isLeader = user?._id === leader_id;

  // const [isLoadingMembers, setIsLoadingMembers] = useState(true);
  const [membersCount, setMembersCount] = useState([]);

  useEffect(() => {
    if (_id) {
      // setIsLoadingMembers(true);
      circleApi
        .getMembersOfCircle(_id)
        .then((res: any) => {
          setMembersCount(res.data.result);
        })
        .catch(() => {
          setMembersCount([]);
        });
    }
  }, [_id]);

  // if (isLoadingMembers) {
  //   return <MaterialCircularPogress />;
  // }

  return (
    <div>
      <div
        className={styles.container}
        style={{
          backgroundImage: matches
            ? `url(${tribe_avatar_url || leader_avatar_url})`
            : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <AvatarComponent isLeader={isLeader} />
        <div className={styles.info}>
          <UserHeader
            tribeId={_id}
            tribeName={tribe_name}
            isLeader={isLeader}
          />
          <UserStats membersCount={membersCount.length} />
          {/* <UserCategories
            categories={categories}
            isLeader={isLeader}
            tribeId={_id}
          /> */}
          <UserActions
            isLeader={isLeader}
            tribeId={_id}
            leaderId={leader_id}
            leaderName={leader_name}
            auctionId={auctionId}
            tribeAvatar={tribe_avatar_url}
            account_type={account_type}
            description={description}
          />
          {auctionId && auctionData && (
            <AuctionSection
              auctionId={auctionId}
              tribeId={_id}
              tribeName={tribe_name}
              leaderName={leader_name}
              tribeAvatar={tribe_avatar_url}
              auctionData={auctionData}
              auctionBidsInfo={auctionBidsInfo}
            />
          )}
        </div>
      </div>
      {auctionId && auctionData && matches && (
        <AuctionSection
          auctionId={auctionId}
          tribeId={_id}
          tribeName={tribe_name}
          leaderName={leader_name}
          tribeAvatar={tribe_avatar_url}
          auctionData={auctionData}
          isMobile={true}
          auctionBidsInfo={auctionBidsInfo}
        />
      )}
    </div>
  );
};
