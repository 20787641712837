import { FC, useState } from 'react';
import { Category } from 'src/components/common/Category/Category';
import styles from './Category.module.scss';

interface ChooseCategoryProps {
  selectedCategory: string[];
  handleCategoryClick: (category: string) => void;
}

export const ChooseCategory: FC<ChooseCategoryProps> = ({
  selectedCategory,
  handleCategoryClick,
}) => {
  const [error, setError] = useState('');

  const handleSelectCategory = (category: string) => {
    if (selectedCategory.includes(category)) {
      handleCategoryClick(category); // Remove if already selected
      setError(''); // Reset error when removing a category
    } else if (selectedCategory.length < 3) {
      handleCategoryClick(category); // Add if under 3
      setError(''); // Reset error on successful selection
    } else {
      setError('You can select up to 3 categories only.'); // Show error message
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Category</div>
      <div className={styles.description}>
        Pick a category under which the Habit falls. You can select up to{' '}
        <strong>3 categories</strong>. If you would like to suggest a new
        category, please <span>contact us.</span>
      </div>
      <Category
        selectedCategory={selectedCategory}
        onCategoryChange={handleSelectCategory}
      />
      {error && <div className={styles.error}>{error}</div>}{' '}
    </div>
  );
};
