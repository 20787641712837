// src/helpers/pollingHelper.ts

import toast from 'react-hot-toast';
import { AppDispatch } from 'src/redux/store/store';
import { messagesApi } from 'src/api/messagesApi';
import {
  fetchCircleMessagesIfLeader,
  fetchMessagesForMember,
} from 'src/redux/slices/circleDetailsSlice/circleDetailsSlice';
import { poll } from 'src/utils/pooling';

export const startPollingForVideoUrl = (
  videoId: string,
  circleId: string,
  dispatch: AppDispatch,
  isLeader: boolean
) => {
  poll(
    () =>
      isLeader
        ? messagesApi.getMessagesLeader(circleId).then((res) => res.data.result)
        : messagesApi.getMessagesMember().then((res) => res.data.replies),
    {
      shouldStop: (messages) =>
        messages.some((msg: any) => msg.video_id === videoId && msg.video_url),
      onSuccess: () => {
        toast.success('Your video is now available!');
        if (isLeader) {
          dispatch(fetchCircleMessagesIfLeader(circleId));
        } else {
          dispatch(fetchMessagesForMember());
        }
      },
      onError: () => {
        toast.error('Video upload timed out. Try again later.');
      },
      intervalMs: 5000,
      maxTries: 36,
    }
  );
};
