import { ResetPasswordOutput, resetPassword } from 'aws-amplify/auth';
import { useState } from 'react';

export const useSendCode = (initialPhoneNumber: string = '') => {
  const [phoneNumber, setPhoneNumber] = useState<string>(initialPhoneNumber);
  const [error, setError] = useState<string>('');
  const formatPhoneNumber = (number: any): string => {
    return number.target.value.replace(/\s+/g, '');
  };

  const sendCode = async (): Promise<ResetPasswordOutput> => {
    try {
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
      const output = await resetPassword({ username: formattedPhoneNumber });
      return output;
    } catch (err: any) {
      setError(err.message);
      throw err;
    }
  };

  return { phoneNumber, setPhoneNumber, sendCode, error, setError };
};
